<template>
   <v-container>

      <v-row class="mt-10">
         <v-col cols="12" align="end">
            <h4 class="mt-5" style="color:#FFD600">Earn</h4>
            <v-divider></v-divider>
         </v-col>

         <v-col class="mt-1" cols="6">
            <div class="d-flex align-center justify-space-between">
               <h2 class="mt-5 mb-5" style="color:#FFD600">Staking</h2>
            </div>
         </v-col>

         <v-col cols="6">
            <v-sheet color="#fed500" style="border-radius: 20px;" class="mx-auto pa-8 mt-5">

               <div class="d-flex align-center">
                  <span style="color: #1f2028;">Available Balance</span>
                  <v-btn class="ml-2" color="primary" icon>
                     <v-icon color="#1f2028">mdi-eye</v-icon>
                  </v-btn>
               </div>

               <div class="d-flex">
                  <h2 style="color: #1f2028; font-weight: 700;">{{ overviewTotal.earnTotal }}<small> USDT</small></h2>
               </div>
            </v-sheet>
         </v-col>

         <v-col class="mt-5" cols="12">
            <div>
               <v-row align="center">
                  <v-col cols="6">
                     <v-checkbox class="ml-3" label="Match my assests"></v-checkbox>
                  </v-col>
                  <v-col cols="6">
                     <v-text-field placeholder="Search Coin" append-icon="mdi-magnify"></v-text-field>
                  </v-col>
               </v-row>
            </div>
         </v-col>

         <v-col cols="12">
            <v-card color="#00000000" class="pa-5" style="border-radius: 20px; border: 1px solid #9493AC">
               <v-data-table :headers="headers" :items="earn" :items-per-page="10" @click:row="rowClicked($event)"
                  class="transparent mt-5">
                  <template v-slot:item.name="{ item }">
                     <v-row>
                        <v-col cols="2" class="text-center" style="margin-top:2px">
                           <v-avatar size="30">
                              <v-img :src="item.image"></v-img>
                           </v-avatar>
                        </v-col>
                        <v-col cols="6">
                           <span style="margin-top:50px">{{ item.name }}</span><br />
                           <span style="font-size:10px;color:grey">{{ (item.name).replace('USDT', '') }}</span>
                           <!-- <span> {{ item.date }}</span><br/> -->
                        </v-col>
                     </v-row>
                  </template>
                  <template v-slot:item.appraisal="{ item }">
                     <v-row>
                        <v-col cols="6">
                           <span v-if="item.apr == 'Not available'">Not Available</span>
                           <span v-else style="color:green">{{ item.apr }}% <v-icon
                                 style="color:green">mdi-arrow-top-right</v-icon></span>

                        </v-col>
                     </v-row>
                  </template>
                  <template v-slot:item.duration="{ item }">
                     <v-row>
                        <v-col cols="6">
                           <span style="color:green">Flexible</span>
                        </v-col>
                     </v-row>
                  </template>

                  <template v-slot:item.actions="{ item }">
                     <v-btn text class="transparent mr-1">Stake<v-icon>mdi-chevron-right</v-icon></v-btn>
                     <!-- <v-btn text class="transparent mr-1">SELL <v-icon>mdi-chevron-right</v-icon></v-btn>
               <v-btn text class="transparent mr-1">TRADE <v-icon>mdi-chevron-right</v-icon></v-btn> -->
                  </template>

               </v-data-table>
            </v-card>
         </v-col>

      </v-row>

   </v-container>
</template>
<script>
import axios from 'axios'
export default {
   data: () => ({
      loading: true,
      earn: [],
      overviewTotal: [],
      headers: [
         {
            value: "name",
            text: "Name",
            align: "start",
            sortable: true,
         },
         {
            value: "appraisal",
            text: "Est. APR",
            align: "start",
            sortable: true,
         },
         {
            value: "duration",
            text: "Duration",
            align: "start",
            sortable: true,
         },
         {
            value: "actions",
            text: "Actions",
            align: "end",
            sortable: true,
         },
      ],
   }),
   props: {
      selectedCoin: {
         type: String,
         required: true,
      },
   },
   computed: {
      orders: function () {
         return this.$store.state.orders;
      },
   },
   watch: {
      selectedCoin: function () {
         clearInterval(this.interval);
         this.$store.commit("setOrders", []);
         this.fetchOrders();
         this.interval = setInterval(() => {
            this.fetchOrders();
         }, 10000);
      },
   },
   methods: {
      fetchEarn() {
         axios.get('https://api.gmswap.net/api/v1/earn/view')
            .then(response => {
               this.earn = response.data.earn
            })
      },

      fetchOverview() {
         axios.get('https://api.gmswap.net/api/v1/overview')
            .then(response => {
               this.overviewTotal = response.data
            })
      }
   },
   mounted() {
      this.fetchEarn()
      this.fetchOverview()
   },
   //Remove/Clear interval and data when component is destroyed
   beforeDestroy() {
      clearInterval(this.interval);
      this.$store.commit("setOrders", []);
   },
};
</script>
