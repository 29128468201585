<template>
   <v-row justify="center">
      <v-col v-if="!$vuetify.breakpoint.xs" class="mt-5" cols="12">
         <v-data-table :loading="isLoading" :headers="headers" :items="orderHistories" :items-per-page="5"
            class="transparent elevation-1 pa-5">
            <template v-slot:item.type="{ item }">
               <v-chip small v-if="item.delegate_type == 'BUY'" color="#0ECB81">{{ $t('trade.buy') }}</v-chip>
               <v-chip small v-if="item.delegate_type == 'SELL'" color="#CA3F64">{{ $t('trade.sell') }}</v-chip>
            </template>
            <template v-slot:item.amount="{ item }">
               <span v-if="item.delegate_type == 'BUY'">{{ Number(item.number_of_order) *
                  Number(item.final_price) }}</span>
               <span v-if="item.delegate_type == 'SELL'">{{ Number(item.number_of_order) *
                  Number(item.final_price) }}</span>
            </template>
            <template v-slot:item.status="{ item }">
               <v-chip small outlined v-if="item.state == 'APPROVED'" color="#0ECB81"> {{ $t('trade.filled') }}</v-chip>
               <v-chip small outlined v-if="item.state == 'DISSAPROVED'" color="#CA3F64">{{ $t('trade.cancelled')
               }}</v-chip>
            </template>
         </v-data-table>
      </v-col>

      <v-col v-else class="mt-5" cols="12">
         <!-- Loading indicator -->
         <div v-if="isLoading" class="text-center">
            <v-progress-circular indeterminate color="primary"></v-progress-circular>
         </div>

         <div v-else>
            <div v-if="orderHistories.length <= 0">

               <div class="d-flex align-center justify-center pa-10" style="height: 100%">
                  <div class="d-flex flex-column align-center justify-center">
                     <v-icon x-large color="#b7bdc6">
                        mdi mdi-note-search-outline
                     </v-icon>
                     <span style="font-size: 12px; color: #b7bdc6">{{ $t('trade.noRecords') }}</span>
                  </div>
               </div>

            </div>

            <div v-else>
               <v-row v-for="{ delegate_type, final_price, number_of_order, coin_pair, state, type } in orderHistories ">
                  <v-col cols="12">

                     <div class="d-flex justify-space-between">
                        <span class="value">
                           {{ coin_pair.pair_name }}
                        </span>

                        <span class="value">
                           {{ final_price }}
                        </span>
                     </div>

                     <div class="d-flex justify-space-between">
                        <span class="label">
                           {{ $t('trade.amount') }}:
                        </span>

                        <span class="value" v-if="delegate_type == 'BUY'">{{ Number(number_of_order) *
                           Number(final_price) }}</span>
                        <span class="value" v-if="delegate_type == 'SELL'">{{ Number(number_of_order) *
                           Number(final_price) }}</span>
                     </div>

                     <div class="d-flex justify-space-between">
                        <span v-if="state == 'APPROVED'" style="color:#0ECB81 ">{{ $t('trade.filled') }}</span>
                        <span v-if="state == 'DISSAPROVED'" style="color:#CA3F64 ">{{ $t('trade.cancelled') }}</span>

                        <v-chip small v-if="delegate_type == 'BUY'" color="#0ECB81">{{ $t('trade.buy') }}</v-chip>
                        <v-chip small v-if="delegate_type == 'SELL'" color="#CA3F64">{{ $t('trade.sell') }}</v-chip>
                     </div>

                  </v-col>
                  <v-divider color="#b7bdc6 "></v-divider>
               </v-row>
            </div>
         </div>

      </v-col>
   </v-row>
</template>

<script>

import { getOrderHistories } from '@/services/Trades';

export default {
   data() {
      return {
         isLoading: false,
         orderHistories: [],
         headers: [
            {
               value: "coin_pair.pair_name",
               text: "Token",
               align: "start",
               sortable: true,
            },
            {
               value: "type",
               text: "Type",
               sortable: true,
               align: "center",
            },

            {
               value: "amount",
               text: "Amount",
               sortable: true,
               align: "end",
            },

            // {
            //   value: "number_of_order",
            //   text: "Number of Orders",
            //   sortable: true,
            //   align: "start",
            // },
            {
               value: "final_price",
               text: "Price",
               sortable: true,
               align: "end",
            },

            {
               value: "status",
               text: "Status",
               sortable: true,
               align: "center",
            },

         ],
      }
   },
   methods: {
      async fetchOrderHistories() {
         try {
            this.isLoading = true; // Set loading state to true before making the request
            const response = await this.$axios.get('https://api.gmswap.net/api/v1/trades/order-histories');
            this.orderHistories = response.data.orders;
         } catch (error) {
            console.error('Error fetching order histories:', error);
         } finally {
            this.isLoading = false; // Set loading state to false after the request is complete (whether it succeeds or fails)
         }
      },
   },

   mounted() {
      this.fetchOrderHistories()
   }
}
</script>

<style>
.label {
   font-size: 16px;
   color: #b7bdc6;
}

.value {
   font-size: 16px;
   font-weight: 500;
}
</style>