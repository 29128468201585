var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[_c('div',{staticClass:"d-flex justify-center",class:!_vm.$vuetify.breakpoint.xs ? 'flex-row' : 'flex-column text-center mt-10',style:(!_vm.$vuetify.breakpoint.xs && 'padding: 120px 8px;')},[_c('div',[_c('h1',{staticStyle:{"color":"#fff"},style:({
        fontSize: !_vm.$vuetify.breakpoint.xs ? '55px' : (_vm.$vuetify.breakpoint.sm || _vm.$vuetify.breakpoint.xs) ? '32px' : '',
        margin: _vm.$vuetify.breakpoint.sm || _vm.$vuetify.breakpoint.xs ? 'auto' : '',
        maxWidth: _vm.$vuetify.breakpoint.sm || _vm.$vuetify.breakpoint.xs ? '500px' : '700px',
        textAlign: _vm.$vuetify.breakpoint.sm || _vm.$vuetify.breakpoint.xs ? 'center' : ''
      })},[_vm._v(" "+_vm._s(_vm.$t('landing.phrase_1'))+" ")]),(_vm.user === null)?_c('div',{staticClass:"mt-10 d-flex",class:_vm.$vuetify.breakpoint.xs && 'justify-center align-center text-center'},[_c('v-row',{attrs:{"align":"center","dense":"","justify":!_vm.$vuetify.breakpoint.lg && 'center'}},[_c('v-col',{attrs:{"cols":" 12","xs":"12","sm":"12","lg":"8"}},[(_vm.$vuetify.breakpoint.xs)?_c('v-btn',{staticClass:"mb-10",staticStyle:{"width":"150px","color":"#1C1D28 !important","font-weight":"700","text-transform":"capitalize","border-radius":"20px"},attrs:{"large":"","color":"#fff","to":"/signin"}},[_vm._v(" "+_vm._s(_vm.$t('landing.getStarted'))+" ")]):_vm._e()],1),(!_vm.$vuetify.breakpoint.xs)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{staticStyle:{"width":"150px","color":"#1C1D28 !important","font-weight":"700","text-transform":"capitalize","border-radius":"20px"},attrs:{"large":"","color":"#ffffff","to":"/signin"}},[_vm._v(" "+_vm._s(_vm.$t('landing.getStarted'))+" ")])],1):_vm._e()],1)],1):_vm._e()]),_c('div',[_c('v-img',{attrs:{"width":"400","height":"580","src":require('@/assets/images/mobile.png')}})],1)]),(!_vm.$vuetify.breakpoint.xs)?_c('v-col',{staticClass:"custom-margin",attrs:{"cols":"12"}},[_c('main-home-download-app')],1):_vm._e(),_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"text-center",style:(!_vm.$vuetify.breakpoint.xs && 'padding: 120px 8px;')},[_c('h1',{staticStyle:{"color":"#fff"},style:({
        fontSize: !_vm.$vuetify.breakpoint.xs ? '55px' : (_vm.$vuetify.breakpoint.sm || _vm.$vuetify.breakpoint.xs) ? '32px' : '',
        margin: _vm.$vuetify.breakpoint.sm || _vm.$vuetify.breakpoint.xs ? 'auto' : '',
        maxWidth: _vm.$vuetify.breakpoint.sm || _vm.$vuetify.breakpoint.xs ? '500px' : '',
        textAlign: _vm.$vuetify.breakpoint.sm || _vm.$vuetify.breakpoint.xs ? 'center' : ''
      })},[_vm._v(" "+_vm._s(_vm.$t('landing.phrase_2'))+" ")])]),_c('div',{staticClass:"py-10 text-center"},[(!_vm.$vuetify.breakpoint.xs)?_c('div',[_c('video',{staticClass:"third-pc-animation",staticStyle:{"visibility":"visible"},attrs:{"autoplay":"","loop":"","playsinline":"","width":"936","height":"253","muted":"","src":"https://www.okx.com/cdn/assets/files/2210/D47D930F643E7A00.webm","poster":"https://www.okx.com/cdn/assets/imgs/2210/2763D233C494439D.jpg?x-oss-process=image/format,webp"},domProps:{"muted":true}},[_c('track',{attrs:{"kind":"captions"}})])]):_c('div',[_c('img',{staticClass:"third-mobile-animation",attrs:{"alt":"With you every step of the wayFrom your first crypto trade to your first NFT purchase, you’ll have us to guide you through the process. No stupid questions. \n              No sleepless nights. Have confidence in your crypto.","width":"400","height":"400","src":"https://www.okx.com/cdn/assets/imgs/2210/602389EA3A7E31BD.gif"}})])])]),_c('div',{staticClass:"custom-margin"},[_c('v-row',{attrs:{"justify":"center","align":"center"}},[_c('v-col',{attrs:{"xs":"12","sm":"8","md":"10"}},[_c('div',{style:(_vm.$vuetify.breakpoint.lg && 'padding: 120px 8px;')},[_c('h1',{staticStyle:{"color":"#fff"},style:({
            fontSize: !_vm.$vuetify.breakpoint.xs ? '55px' : (_vm.$vuetify.breakpoint.sm || _vm.$vuetify.breakpoint.xs) ? '32px' : '',
            margin: _vm.$vuetify.breakpoint.sm || _vm.$vuetify.breakpoint.xs ? 'auto' : '',
            maxWidth: _vm.$vuetify.breakpoint.sm || _vm.$vuetify.breakpoint.xs ? '500px' : '',
            textAlign: 'center'
          })},[_vm._v(" "+_vm._s(_vm.$t('landing.newsHeader'))+" ")]),(_vm.isLoading)?_c('v-progress-circular',{staticClass:"my-10",staticStyle:{"top":"50%","bottom":"50%","left":"50%","transform":"translate(-50%, -50%)"},attrs:{"indeterminate":"","color":"primary"}}):_c('div',{staticClass:"my-10"},[(!_vm.$vuetify.breakpoint.xs)?_c('v-row',{staticClass:"fill-height",attrs:{"align":"center","justify":"center"}},_vm._l((_vm.posts),function({ id, url, imageurl, title, body }){return _c('v-col',{key:id,attrs:{"cols":"4","xs":"12","sm":"10","md":"6","lg":"4"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('v-card',{class:{ 'on-hover': hover },staticStyle:{"border":"1px solid lightgray"},attrs:{"href":url,"tag":"a","target":"_blank","elevation":hover ? 12 : 2}},[_c('v-img',{staticClass:"white--text align-end",attrs:{"height":"300px","src":imageurl,"gradient":hover
                      ? 'to bottom, rgba(0,0,0,.1), rgba(0,0,0,.8)'
                      : 'to bottom, rgba(0,0,0,.1), rgba(0,0,0,1)'}},[_c('div',{staticClass:"d-flex align-center justify-space-between pa-3"},[_c('v-container',[_c('div',[_c('span',{staticStyle:{"color":"#fff","font-size":"22px","font-weight":"700","line-height":"32px"}},[_vm._v(_vm._s(title))])]),_c('div',[_c('span',{staticClass:"mt-2",staticStyle:{"font-size":"14px","display":"block","white-space":"nowrap","overflow":"hidden","text-overflow":"ellipsis"}},[_vm._v(" "+_vm._s(body)+" ")])])])],1)])],1)]}}],null,true)})],1)}),1):_vm._e()],1)],1),_vm._l((_vm.posts),function({ id, url, title, body }){return (_vm.$vuetify.breakpoint.xs)?_c('v-list-item',{key:id,staticStyle:{"border-bottom":"1px solid #949ca3"},attrs:{"href":url,"tag":"a","target":"_blank"}},[_c('v-list-item-content',[_c('v-list-item-title',[_c('span',{staticStyle:{"font-weight":"700"}},[_vm._v(" "+_vm._s(title))])]),_c('span',{staticClass:"mt-2",staticStyle:{"font-size":"14px","display":"block","white-space":"nowrap","overflow":"hidden","text-overflow":"ellipsis"}},[_vm._v(" "+_vm._s(body))])],1)],1):_vm._e()})],2)],1)],1),_c('div',{staticClass:"custom-margin"},[_c('main-home-questions')],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }