<template>
  <div>
    <v-row justify="center" align="center">
      <v-col xs="12" sm="12" lg="12">
        <div class="text-center">
          <h1 style="color: #fff;" :style="{
            fontSize: !$vuetify.breakpoint.xs ? '55px' : ($vuetify.breakpoint.sm || $vuetify.breakpoint.xs) ? '32px' : '',
            margin: $vuetify.breakpoint.sm || $vuetify.breakpoint.xs ? 'auto' : '',
            maxWidth: $vuetify.breakpoint.sm || $vuetify.breakpoint.xs ? '500px' : '',
            textAlign: $vuetify.breakpoint.sm || $vuetify.breakpoint.xs ? 'center' : ''
          }">
            {{ $t('landing.phrase_3') }}
          </h1>
          <span class="mt-5" style="color: #6e6e6e;">
            {{ $t('landing.subtitle_1') }}
          </span>
          <div class="">
            <v-img :src="require('../../../assets/images/both.png')" style="width:70%; margin: 0 auto;" />
          </div>

        </div>
      </v-col>
    </v-row>
  </div>
</template>
<script>

import VueQrcode from "@chenfengyuan/vue-qrcode";

export default {
  data: () => ({
    loading: true,
  }),

  components: {
    VueQrcode,
  },

  mounted() {
    setTimeout(() => {
      this.loading = false;
    }, 1000);
  },
  computed: {
    marketCoins() {
      return this.$store.getters.marketCoins;
    },
    filteredCoins() {
      return this.marketCoins.filter((coin) => coin.type === "MAIN");
    },
  },
};
</script>
<style>
#phones {
  /* max-height: 50px; */
  /* max-width:100px;  */
  /* object-fit: contain; */
}

.theme--dark.v-tabs-items {
  background-color: transparent !important;
}
</style>
