<template>
  <v-row justify="center">

    <div style="background-color: #fff;">
      <v-row justify="center">
        <v-col cols="12" xs="12" lg="10" xl="10">
          <div style="padding: 150px;" :class="$vuetify.breakpoint.xs || $vuetify.breakpoint.md ? 'pa-5' : 'pa-15'">
            <v-row justify="center">
              <v-col cols="12" xs="12" sm="10" md="6" lg="6">
                <div>
                  <h2
                    :class="$vuetify.breakpoint.xs ? 'display-1 text-center mt-10' : $vuetify.breakpoint.sm ? 'display-2' : ''"
                    style="color: #1f2933; font-size: 48px; font-weight: 700;">Rewards Center.
                  </h2>
                  <h2
                    :class="$vuetify.breakpoint.xs ? 'display-1 text-center' : $vuetify.breakpoint.sm ? 'display-2' : ''"
                    style="color: #1f2933; font-size: 32px; font-weight: 500;">Finish tasks to receive special rewards.
                  </h2>
                </div>
              </v-col>

              <v-col cols="12" xs="12" sm="8" md="6" lg="6" align="center">
                <div>
                  <v-img max-height="200px" max-width="300px" style="border-radius:10px"
                    :src="require('../../../assets/images/rewards.jpg')"></v-img>
                </div>
              </v-col>
            </v-row>
          </div>
        </v-col>
      </v-row>
    </div>

    <div class="my-10">
      <v-row justify="center">
        <v-col cols="12" xs="12" sm="8" md="6" lg="8">
          <div style="font-size: 28px; font-weight: 700; margin-bottom: 20px;  color: #F7F9FC; ">
            Tasks
          </div>

          <div class="mb-5">
            <v-slide-group mandatory v-model="selectedOption" active-class="#5E6673">
              <v-slide-item v-for="(option, index) in options" :key="index" v-slot="{ active, toggle }">
                <v-btn small text class="" :input-value="active" @click="toggle">
                  {{ option }}
                </v-btn>
              </v-slide-item>
            </v-slide-group>
          </div>

          <div v-if="tasks.length === 0" class="mb-5">
            <center style="font-size: 28px; font-weight: 700; margin-bottom: 15px;  color: grey; ">No tasks are currently available. Please try again later.</center>
          </div>

          <div v-else>
            <div v-if="selectedOption === 0">
              <div v-for="task in tasks" >
                <v-sheet v-if="task.reward_status != 'CLAIMED'" class="pa-7 my-2" color="dark" elevation="24" rounded>
                  <v-row  justify="space-between">
                      <v-col cols="12" xs="12" sm="10" md="6">
                          <div class="d-flex flex-column">
                              <div class="d-flex flex-column">

                                <div>
                                  <v-chip small>Task {{ task.task_center.coin_id}}</v-chip>
                                  <h4 class="mt-2">
                                    {{ task.task_center.description}}
                                  </h4>
                                </div>

                                <div
                                    style="position: relative; width: 100%;font-size: 14px;  margin-inline-end: 8px; margin-bottom: 8px;">
                                    <!-- accumulated_percentage: (accumulated_amount / amount) * 100, -->
                                    <v-progress-linear rounded height="20" :value="(task.accumulated_amount / task.task_center.amount) * 100" class="mt-2">
                                      <template v-slot:default="{ value }">
                                        <strong  style="color: #1f2933;">{{ task.accumulated_amount }} / {{ task.task_center.amount }}</strong>
                                      </template>
                                    </v-progress-linear>
                                </div>
                              </div>

                              <div class="mt-3">
                                  <span>
                                      {{ task.task_center.rules }}
                                  </span>
                              </div>
                          </div>
                      </v-col>

                      <v-col cols="12" xs="12" sm="10" md="6" :align="!$vuetify.breakpoint.xs ? 'end' : ''">
                          <div>
                            <div class="d-flex flex-column">
                              <span>Reward <v-icon class="ml-2">mdi-gift-outline</v-icon></span>
                              <h4>{{task.task_center.reward_amount}} Points</h4>
                            </div>
                              <v-btn @click="claimTask(task)" :disabled="task.accumulated_amount !== task.task_center.amount" color="primary" class="black--text" rounded>Claim now</v-btn>
                          </div>
                      </v-col>
                  </v-row>
                </v-sheet>

                <v-else>
                  <center style="font-size: 28px; font-weight: 700; margin-bottom: 15px;  color: grey; ">No tasks available </center>
                </v-else>
              </div>
            </div>

            <div v-else-if="selectedOption === 1">
              <!-- <Weekly /> -->
            </div>

            <div v-else-if="selectedOption === 2">
              <div v-for="task in tasks">

                <v-sheet v-if="task.reward_status == 'CLAIMED'" class="pa-7 my-2" color="dark" elevation="24" rounded>
                  <v-row>
                    <v-col cols="12" xs="12" sm="10" md="6">
                          <div class="d-flex flex-column">
                              <div class="d-flex flex-column">

                                <div>
                                  <v-chip small>Task {{ task.task_center.coin_id}}</v-chip>
                                  <h4 class="mt-2">
                                    {{ task.task_center.description}}
                                  </h4>
                                </div>

                                <div
                                    style="position: relative; width: 100%;font-size: 14px;  margin-inline-end: 8px; margin-bottom: 8px;">
                                    <!-- accumulated_percentage: (accumulated_amount / amount) * 100, -->
                                    <v-progress-linear rounded height="20" :value="(task.accumulated_amount / task.task_center.amount) * 100" class="mt-2">
                                      <template v-slot:default="{ value }">
                                        <strong  style="color: #1f2933;">{{ task.accumulated_amount }} / {{ task.task_center.amount }}</strong>
                                      </template>
                                    </v-progress-linear>
                                </div>
                              </div>

                              <div class="mt-3">
                                  <span>
                                      {{ task.task_center.rules }}
                                  </span>
                              </div>
                          </div>
                    </v-col>

                    <v-col cols="12" xs="12" sm="10" md="6" :align="!$vuetify.breakpoint.xs ? 'end' : ''">
                          <div>
                            <div class="d-flex flex-column">
                              <span>Reward <v-icon class="ml-2">mdi-gift-outline</v-icon></span>
                              <h4>{{task.task_center.reward_amount}} USDT</h4>
                            </div>
                              <v-btn @click="claimTask(task)" disabled color="primary" class="black--text" rounded>Claimed</v-btn>
                          </div>
                      </v-col>

                  </v-row>
                </v-sheet>

                <div v-else>
                  <center style="font-size: 28px; font-weight: 700; margin-bottom: 15px;  color: grey; ">You have not completed any task yet</center>
                </div>
              </div>
            </div>
          </div>
        </v-col>
      </v-row>

      <!-- <v-row justify="center" class="my-10">
        <v-col cols="12" xs="12" sm="8" md="6" lg="8">
          <div style=" font-size: 28px; font-weight: 700; margin-bottom: 20px; color: #F7F9FC; ">
            CONVERT YOUR POINTS INTO USDT!
          </div>

          <v-row justify=" center" class="mt-10">

            <v-col xs="6" sm="6" md="4" lg="3" align="center">
              <v-sheet class="pa-3" rounded>
                <div class="d-flex flex-column">
                  <div style="font-size: 30px; font-weight: 700;">3</div>
                  <div style="font-size: 1rem; font-weight: 500;">USDT</div>
                  <span class="mt-2" style="font-size: 14px; font-weight: 400;">500 Points</span>
                  <v-btn small color="primary" class="black--text mt-1" rounded>Claim now</v-btn>
                </div>
              </v-sheet>
            </v-col>

            <v-col xs="6" sm="6" md="4" lg="3" align="center">
              <v-sheet class="pa-3" rounded>
                <div class="d-flex flex-column">
                  <div style="font-size: 30px; font-weight: 700;">10</div>
                  <div style="font-size: 1rem; font-weight: 500;">USDT</div>
                  <span class="mt-2" style="font-size: 14px; font-weight: 400;">1500 Points</span>
                  <v-btn small color="primary" class="black--text mt-1" rounded>Claim now</v-btn>
                </div>
              </v-sheet>
            </v-col>

            <v-col xs="6" sm="6" md="4" lg="3" align="center">
              <v-sheet class="pa-3" rounded>
                <div class="d-flex flex-column">
                  <div style="font-size: 30px; font-weight: 700;">30</div>
                  <div style="font-size: 1rem; font-weight: 500;">USDT</div>
                  <span class="mt-2" style="font-size: 14px; font-weight: 400;">3000 Points</span>
                  <v-btn small color="primary" class="black--text mt-1" rounded>Claim now</v-btn>
                </div>
              </v-sheet>
            </v-col>

            <v-col xs="6" sm="6" md="4" lg="3" align="center">
              <v-sheet class="pa-3" rounded>
                <div class="d-flex flex-column">
                  <div style="font-size: 30px; font-weight: 700;">50</div>
                  <div style="font-size: 1rem; font-weight: 500;">USDT</div>
                  <span class="mt-2" style="font-size: 14px; font-weight: 400;">3500 Points</span>
                  <v-btn small color="primary" class="black--text mt-1" rounded>Claim now</v-btn>
                </div>
              </v-sheet>
            </v-col>

            <v-col xs="6" sm="6" md="4" lg="3" align="center">
              <v-sheet class="pa-3" rounded>
                <div class="d-flex flex-column">
                  <div style="font-size: 30px; font-weight: 700;">100</div>
                  <div style="font-size: 1rem; font-weight: 500;">USDT</div>
                  <span class="mt-2" style="font-size: 14px; font-weight: 400;">5000 Points</span>
                  <v-btn small color="primary" class="black--text mt-1" rounded>Claim now</v-btn>
                </div>
              </v-sheet>
            </v-col>

            <v-col xs="6" sm="6" md="4" lg="3" align="center">
              <v-sheet class="pa-3" rounded>
                <div class="d-flex flex-column">
                  <div style="font-size: 30px; font-weight: 700;">500</div>
                  <div style="font-size: 1rem; font-weight: 500;">USDT</div>
                  <span class="mt-2" style="font-size: 14px; font-weight: 400;">5000 Points</span>
                  <v-btn small color="primary" class="black--text mt-1" rounded>Claim now</v-btn>
                </div>
              </v-sheet>
            </v-col>

          </v-row>
        </v-col>
      </v-row> -->
    </div>

  </v-row>
</template>
<script>

import Daily from '../Home/Rewards/Daily.vue';
import Weekly from '../Home/Rewards/Weekly.vue'

import Swal from 'sweetalert2';

export default {

  components: {
    Daily,
    Weekly,
  },

  data: () => ({
    loading: false,
    selectedOption: 0,
    options: ['Daily', 'Weekly', 'Completed'],
    // accumulated_percentage: (accumulated_amount / amount) * 100,
    tasks: [
      {
        amount: 0,
        task_status: "pending",
        task_center: {
          id: 1,
          description: "Watch 1 video",
          reward_amount: 1,
          amount: 1,
        },
      },
    ],
  }),

  methods: {

    // fetchRewards() {
    //   this.loading = true;
    //   this.$axios
    //     .get("https://api.gmswap.net/api/v1/task/reward")
    //     .then((response) => {
    //       this.tasks = response.data.client_task;
    //       this.loading = false;
    //     })
    //     .catch((error) => {
    //       this.loading = false;
    //       console.log(error);
    //     });
    // },

    fetchTasks(){
      this.loading = true
      this.$axios
      .get('https://api.gmswap.net/api/v1/task/view')
      .then((response) => {
        this.tasks = response.data.tasks
      })
    },

    progress(task) {
      return (task.amount / task.task_center.amount) * 100;
    },

    claimTask(task) {
      this.loading = true;
      this.$axios
        .get("https://api.gmswap.net/api/v1/claim-task", {
          params: { task_center_id: task.id },
        })
        .then(() => {
          // Show success notification
          Swal.fire({
            icon: 'success',
            title: 'Claimed Rewards',
            text: 'You have successfully claimed the rewards!',
          });

          this.fetchTasks();
          this.$store.dispatch("setWallet");
        })
        .catch((error) => {
          console.log(error);
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Failed to claim rewards. Please try again later.',
          });
        }).finally(() => {
          this.loading = false; // Ensure loading state is reset
        });
    },
  },
  mounted() {
    this.fetchTasks()
    // this.fetchRewards();
  },
};
</script>
