<template>
    <v-container>
        <v-row class="mt-10" justify="center" align="center">
            <v-col cols="12" xs="12" lg="4" class="text-center">
                <div class="text-center">
                    <img class="" src="@/assets/images/gmswap-logo.png" style="max-width: 120px; max-height: 120px;" />
                    <!-- <span>GMSwap</span> -->
                </div>
                <h4>
                    Redirecting please wait...
                </h4>

            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import ButtonComponent from '@/components/ui/ButtonComponent.vue';

export default {
    components: {
        ButtonComponent,
    },

    mounted()
    {
        const params = new URLSearchParams(window.location.search);
        const jsonData = params.get('data');
        const email = decodeURIComponent(jsonData);

    
        this.$axios
          .post("https://api.gmswap.net/api/v1/google-login", {
            username: email,           
          })
          .then((response) => {
            if (response.status === 200) {
              if (response.data.client.status == "FREEZE_ACCOUNT") {
                this.loading = false;
                this.$swal({
                  title: "Freezed Account!",
                  html: "Your account has been freezed. Please contact <a class='primary-text' href='https://direct.lc.chat/17786427/' target='_blank'>support</a> for more details.",
                  icon: "error",
                  confirmButtonText: "OK",
                });
              } else {
                this.$store.dispatch("setMarketCoins");
                this.$store.commit("setToken", response.data.token);
                this.$store.commit("setUser", response.data.user);
                this.$store.commit("setClient", response.data.client);
                this.$store.dispatch("setWallets");
                this.$store.dispatch("fetchClientUser");
                //Set 1.5 seconds timeout to redirect to dashboard
                setTimeout(() => {
                  // this.$swal({
                  //   toast: true,
                  //   position: "top-end",
                  //   title: "Login Success!",
                  //   icon: "success",
                  //   showConfirmButton: false,
                  //   timer: 3000, // milliseconds
                  // });

                  if (this.$route.path !== '/wallet') {
                    // Using Vue Router to navigate to '/wallet' route
                    this.$router.push("/wallet");
                  }
                  this.loading = false;
                }, 1500);
              }
            } else {
              this.loading = false;
              this.register()
              // this.$swal({
              //   title: "Error!",
              //   text: "Login Failed",
              //   icon: "error",
              //   confirmButtonText: "OK",
              // });
              // this.$router.push("/signin");
            }
          })
          .catch((error) => {
            console.log(error);
            this.register()
            // this.loading = false;
            // this.$swal({
            //   title: "Error!",
            //   text: 'Something went wrong',
            //   icon: "error",
            //   confirmButtonText: "OK",
            // });
            // this.$router.push("/signin");
          });
    },
    methods: {
        register()
        {
          const params = new URLSearchParams(window.location.search);
          const jsonData = params.get('data');
          const email = decodeURIComponent(jsonData);
            sessionStorage.setItem('gmail-register', 1)
          sessionStorage.setItem('email', email)
          this.$router.push('/signup')

        }
    }
}
</script>