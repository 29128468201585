<template>
    <v-container>

        <v-row>
            <v-col cols="12">
                <div class="d-flex align-center">
                    <v-toolbar class="transparent elevation-0">
                        <v-btn icon @click="$router.push('/account/security');">
                            <icon icon="material-symbols:arrow-back" width="24" height="24"></icon>
                        </v-btn>
                        <v-toolbar-title> {{ $t('security.security') }}</v-toolbar-title>
                    </v-toolbar>
                </div>
            </v-col>

            <v-col cols=12 class="mt-2 mb-10" align="center">
                <v-row>
                    <v-col cols="12" xs="12" lg="5">
                        <h4 style="color: #FAFBFC; font-size: 32px;">
                            <strong>
                                {{ $t('security.enableAnti') }}
                            </strong>
                        </h4>

                        <v-alert outlined type="warning" text class="mt-5">
                            <span style="color: #FAFBFC">
                                {{ $t('security.enableDesc') }}
                            </span>
                        </v-alert>
                    </v-col>
                </v-row>

                <v-row>
                    <v-col cols="12" xs="12" lg="5" align="start">

                        <div class="mb-10">
                            <div class="mb-2" style="font-size: 16px; font-weight: 700;">
                                {{ $t('security.question1') }}
                            </div>

                            <p style="color:#9493AC; color: 14px;">
                                {{ $t('security.answer1') }}
                            </p>
                        </div>

                        <div>
                            <div class="mb-2" style="font-size: 16px; font-weight: 700;">
                                {{ $t('security.question2') }}
                            </div>

                            <p style="color:#9493AC; color: 14px;">
                                {{ $t('security.answer2') }}
                            </p>
                        </div>

                        <div>
                            <v-btn @click="antiPhishingdialog = true" block x-large color="primary" class="custom-btn">
                                {{ $t('security.createAntiPhishing') }}
                            </v-btn>
                        </div>

                        <!-- Anti-Phishing Dialog -->
                        <v-dialog v-model="antiPhishingdialog" max-width="400px">
                            <v-card class="pa-5" color="#1F2339">

                                <v-card-title>
                                    <span class="text-h5">{{ $t('security.createAntiPhishing') }}</span>
                                </v-card-title>

                                <v-card-text>
                                    <v-row class="mt-3">
                                        <v-col cols="12">
                                            <v-text-field label="Anti-phishing Code" v-model="anti_phishing_code" clearable
                                                required></v-text-field>
                                            <small>{{ $t('security.errorMessage') }} </small>
                                        </v-col>
                                    </v-row>
                                </v-card-text>

                                <v-card-actions>
                                    <v-btn @click="openSecurity()" color="primary" class="custom-btn" block large>
                                        {{ $t('dialog.submit') }}
                                    </v-btn>
                                </v-card-actions>

                            </v-card>
                        </v-dialog>


                        <!-- Security Dialog -->
                        <v-dialog v-model="securityDialog" max-width="400px">

                            <v-card class="p-3" color="#1F2339">

                                <v-card-title>
                                    <span class="text-h5">{{ $t('security.securityVerification') }}</span>
                                </v-card-title>

                                <v-card-text>
                                    <v-row class="mt-1">
                                        <v-col cols="12">
                                            <v-text-field label="Verification Code" clearable v-model="verification_code"
                                                required></v-text-field>

                                            <!-- <small>Enter code that was sent to 0909090909</small> -->
                                        </v-col>

                                        <v-col cols="12" class="mt-3">
                                            <!-- <a href="#"> Security verification unavailable?</a>  -->
                                        </v-col>
                                    </v-row>
                                </v-card-text>

                                <v-card-actions>
                                    <v-btn color="primary" class="custom-btn" block large @click="savePhishingCode()">
                                        {{ $t('dialog.submit') }}
                                    </v-btn>
                                </v-card-actions>

                            </v-card>
                        </v-dialog>

                    </v-col>
                </v-row>
            </v-col>
        </v-row>

    </v-container>
</template>

<script>
import axios from 'axios'
export default {
    data: () => ({
        antiPhishingdialog: false,
        securityDialog: false,
        anti_phishing_code: '',
        verification_code: '',
    }),

    methods: {
        openSecurity() {
            axios.get('https://api.gmswap.net/api/v1/get-code/anti-phishing')
                .then(response => {
                    this.antiPhishingdialog = false
                    this.securityDialog = true
                })

        },
        savePhishingCode() {
            axios.get('https://api.gmswap.net/api/v1/anti-phishing',
                {
                    params: {
                        anti_phishing_code: this.anti_phishing_code,
                        verification_code: this.verification_code
                    }
                })
                .then(response => {
                    this.securityDialog = false
                    this.$swal({
                  title: "Success",
                  text: "Saved",
                  icon: "success",
                  timer: 3000,
                  showConfirmButton: false,
                });
                });
        },
    },
}
</script>


<style>
.custom-btn .v-btn__content {
    color: #1C1D28;
    /* Change this to your desired text color */
    text-transform: capitalize;
    font-weight: 700;
}
</style>