<template>
  <!-- <v-app-bar :color="appBarColor" :height="appBarHeight" class="sticky-appbar" fixed elevation="0"> -->
  <v-app-bar class="pa-2" app color="#121212" elevation="0">

    <v-container fluid>

      <div class="d-flex align-center justify-space-between">

        <div class="d-flex align-center">

          <v-toolbar-title @click="goHome()" style="cursor: pointer">
            <div class="d-flex align-center">
              <v-avatar class="" size="30">
                <img src="@/assets/images/gmswap-logo.png" />
              </v-avatar>
              <span class="ml-2" style="font-size: 16px; font-weight: 700; color: #FFD600"> GMSWAP</span>
            </div>
          </v-toolbar-title>

          <div class="d-flex align-center ml-2">
            <template v-if="!$vuetify.breakpoint.xs && !$vuetify.breakpoint.sm">
              <v-menu open-on-hover style="margin-left:15px">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn to="/markets/prices" text v-bind="attrs" v-on="on">
                    {{ $t('nav.market') }}
                  </v-btn>
                </template>
              </v-menu>

              <v-menu style="margin-left:15px">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn to="/trades/trade-chart/BTC" text v-bind="attrs" v-on="on">
                    {{ $t('nav.trade') }}
                  </v-btn>
                </template>
              </v-menu>

              <v-menu style="margin-left:15px">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn to="/trades/futures/BTC" text v-bind="attrs" v-on="on">
                    {{ $t('nav.future') }}
                  </v-btn>
                </template>
              </v-menu>

              <v-menu style="margin-left:15px" open-on-hover bottom offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn text v-bind="attrs" v-on="on">
                    {{ $t('nav.earn') }}
                    <icon icon="heroicons-solid:chevron-down" width="24" height="24" />
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item to="/wallet/simple_earn">
                    <v-list-item-icon>
                      <v-icon>mdi-account</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title> {{ $t('nav.simpleEarn') }}</v-list-item-title>
                  </v-list-item>
                  <v-list-item to="/wallet/auto_invest">
                    <v-list-item-icon>
                      <v-icon>mdi-cog</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title> {{ $t('nav.autoInvest') }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>

            <!-- <v-hover v-slot="{ hover }">
            <v-btn
              :color="hover ? 'primary' : ''"
              class="white--text"
              :text="hover ? false : true"
              :class="hover ? '' : 'white--text'"
              @click="goToSection('download')"
            >
              <v-icon>mdi-download</v-icon>  
            </v-btn
            >
          </v-hover> -->

            <!-- <v-btn icon><icon icon="heroicons:bell-20-solid" width="24" height="24" /></v-btn> -->
          </div>
        </div>

        <div v-if="isAuthenticated">
          <div class="d-flex align-center">

            <!-- <v-btn @click="dialog = true" color="primary" small
            style="width: 95px; text-transform: capitalize; color: #1C1D28; font-weight: 700;">
            <v-icon style="font-size:18px;margin:5px">mdi-tray-arrow-down</v-icon>
            Deposit
          </v-btn> -->

            <v-btn v-if="!$vuetify.breakpoint.xs" to="/wallet" icon v-bind="attrs" v-on="on">
              <icon icon="mdi-wallet-bifold-outline" width="24" height="24" />
            </v-btn>

            <v-menu v-if="!$vuetify.breakpoint.xs" open-on-hover bottom offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on">
                  <icon icon="material-symbols:account-circle" width="24" height="24" />
                </v-btn>
              </template>

              <v-list>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title class="text-h6">
                      {{ user.email }}
                    </v-list-item-title>

                    <v-list-item-subtitle>
                      <v-chip v-if="client.user.account_status == 'Unverified'" class="mt-2">{{ client.user.account_status
                      }}</v-chip>

                      <v-chip v-else-if="client.user.account_status == 'Verified'" class="mt-2"> <v-icon color="success"
                          size="15">mdi-check-circle-outline</v-icon>{{ client.user.account_status }}</v-chip>

                      <!-- <v-chip v-if="client.membership_level !== 'NO_BADGE'" class="ma-2" color="greyPrimary" small label>
                        {{ client.membership_level }}
                      </v-chip> -->

                      <v-chip class="mt-2 ml-2" v-if="client.membership_level == 'NO_BADGE'"
                        style="font-size: 12px; font-weight: 500"> {{ $t('nav.noBadge') }}</v-chip>
                      <v-chip class="mt-2 ml-2" v-else style="font-size: 12px; font-weight: 500"> <v-icon
                          color="yellow">mdi-crown-circle</v-icon> {{ client.membership_level }}</v-chip>

                    </v-list-item-subtitle>
                  </v-list-item-content>

                  <!-- <v-list-item-action>
                    <v-icon>mdi-menu-down</v-icon>
                  </v-list-item-action> -->
                </v-list-item>

                <v-divider></v-divider>

                <v-list-item v-for="(item, index) in accountMenus" :key="index" :to="item.path">
                  <v-list-item-title>
                    <icon class="mr-1" :icon="item.icon" width="24" height="24" />
                    {{ item.title }}
                  </v-list-item-title>
                </v-list-item>

                <v-list-item href="https://direct.lc.chat/17786427/" target="_blank">
                  <v-list-item-title>
                    <icon class="mr-1" icon="solar:dialog-bold-duotone" width="24" height="24" />
                    {{ $t('account.help') }}
                  </v-list-item-title>
                </v-list-item>
                <v-list-item @click="logout()">
                  <v-list-item-title>
                    <icon class="mr-1" icon="solar:logout-2-bold-duotone" width="24" height="24" />
                    {{ $t('home.logout') }}
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>

            <v-btn icon v-if="$vuetify.breakpoint.xs" @click="accountMenuDialog = true">
              <icon icon="material-symbols:account-circle" width="24" height="24" />
            </v-btn>

            <v-btn v-if="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm" icon @click="menuDialog = true">
              <v-icon>mdi mdi-menu</v-icon>
            </v-btn>

          </div>
        </div>

        <div v-else class="d-flex align-center">

          <v-btn v-if="$route.path !== '/signin' && !$vuetify.breakpoint.xs" @click="menuDialog = false" text
            class="white--text mr-2" to="/signin" small style="border-radius: 20px;">
            {{ $t('nav.login') }}
          </v-btn>

          <v-btn @click="menuDialog = false" outlined class="white--text mr-2" to="/signup" small
            style="border: 1px solid #fafafa; border-radius: 20px;">
            {{ $t('signUp.signUp') }}
          </v-btn>

          <!-- <v-btn v-if="!$vuetify.breakpoint.xs" small text class="white--text mr-2" to="/signin"
            style="border-radius: 20px;">
            Log in
          </v-btn>

          <v-btn outlined class="mr-2 white--text" to="/signup" small
            style="border: 1px solid #fafafa; border-radius: 20px;">
            Sign up
          </v-btn> -->

          <v-btn v-if="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm" icon @click="menuDialog = true">
            <v-icon color="#fafafa">mdi mdi-menu</v-icon>
          </v-btn>

          <!-- <v-hover v-slot="{ hover }">
            <v-btn
              :color="hover ? 'primary' : ''"
              class="white--text"
              :text="hover ? false : true"t'"
              @click="goToSection('download')"
              :class="hover ? '' : 'white--tex
            >
              <v-icon>mdi-download</v-icon>  
            </v-btn
            >
          </v-hover> -->
        </div>

      </div>

      <!-- mobile navigation bar -->
      <v-dialog dark v-model="menuDialog" fullscreen hide-overlay transition="dialog-bottom-transition" scrollable>
        <v-card>

          <div class="m-2 d-flex align-end">
            <v-spacer></v-spacer>
            <v-btn small fab icon @click="menuDialog = false">
              <v-icon size="20" color="#909090">mdi-close</v-icon>
            </v-btn>
          </div>


          <div v-if="!isAuthenticated" class="m-2 d-flex align-center">
            <v-btn @click="menuDialog = false" outlined class="white--text mr-2" to="/signup" small
              style="border: 1px solid #fafafa; border-radius: 20px;">
              {{ $t('signUp.signUp') }}
            </v-btn>

            <v-btn @click="menuDialog = false" text class="white--text" to="/signin" small style="border-radius: 20px;">
              {{ $t('nav.login') }}
            </v-btn>
          </div>

          <!-- <div>
            <v-text-field outlined x-small placeholder="Search Coin" prepend-inner-icon="mdi-magnify"></v-text-field>
          </div> -->

          <template v-for="item in items">
            <v-list-group v-if="item.items && item.items.length > 0" :key="item.title" v-model="item.active"
              :prepend-icon="item.action" no-action>
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title v-text="item.title"></v-list-item-title>
                </v-list-item-content>
              </template>

              <v-list-item v-for="child in item.items" :key="child.title" @click="navigate(child.path)">
                <v-list-item-content>
                  <v-list-item-title v-text="child.title"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>

            <v-list-item @click="menuDialog = false" style="flex: none !important; cursor: pointer;" :to="item.path"
              v-else :key="item.title">
              <v-list-item-content>
                <v-list-item-title v-text="item.title"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>

          </template>

        </v-card>
      </v-dialog>

      <!-- mobile profile menu-->
      <v-dialog v-if="isAuthenticated" v-model="accountMenuDialog" fullscreen hide-overlay
        transition="dialog-bottm-transition" scrollable>
        <v-card>

          <v-btn icon @click="accountMenuDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>

          <v-card-content>
            <v-list>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title class="text-h6">
                    {{ user.email }}
                  </v-list-item-title>

                  <v-list-item-subtitle>
                    <v-chip v-if="client.user.account_status == 'Unverified'" class="mt-2">{{ client.user.account_status
                    }}</v-chip>
                    <v-chip v-else-if="client.user.account_status == 'Verified'" class="mt-2"> <v-icon color="success"
                        size="15">mdi-check-circle-outline</v-icon>{{ client.user.account_status }}</v-chip>
                    <!-- <v-chip v-if="client.membership_level !== 'NO_BADGE'" class="ma-2" color="greyPrimary" small label>
                        {{ client.membership_level }}
                      </v-chip> -->
                  </v-list-item-subtitle>
                </v-list-item-content>

                <!-- <v-list-item-content>
                  <v-list-item-title v-if="isAuthenticated" class="text-h6">
                    {{ user.email }}
                  </v-list-item-title>
                  <v-chip v-if="user.account_status == 'Unverified'" class="ma-2">{{ user.account_status }}</v-chip>
                  <v-chip v-else-if="user.account_status == 'Verified'" class="ma-2"> <v-icon color="success"
                      size="15">mdi-check-circle-outline</v-icon>{{ user.account_status }}</v-chip>
                </v-list-item-content> -->

                <!-- <v-list-item-action>
                  <v-icon>mdi-menu-down</v-icon>
                </v-list-item-action> -->
              </v-list-item>

              <v-list-item @click="accountMenuDialog = false" v-for="(item, index) in accountMenus" :key="index"
                :to="item.path">
                <v-list-item-title>
                  <icon class="mr-1" :icon="item.icon" width="24" height="24" />
                  {{ item.title }}
                </v-list-item-title>
              </v-list-item>

              <v-list-item href="https://direct.lc.chat/17786427/" target="_blank">
                <v-list-item-title>
                  <icon class="mr-1" icon="solar:dialog-bold-duotone" width="24" height="24" />
                  {{ $t('account.help') }}
                </v-list-item-title>
              </v-list-item>

              <v-list-item @click="logout()">
                <v-list-item-title>
                  <icon class="mr-1" icon="solar:logout-2-bold-duotone" width="24" height="24" />
                  {{ $t('home.logout') }}
                </v-list-item-title>
              </v-list-item>

            </v-list>
          </v-card-content>

        </v-card>
      </v-dialog>

      <v-dialog v-model="dialog" max-width="0" class="elevation-0" color="#00000000">
        <v-card class="right-dialog" style="height:100vh">
          <v-card-title>
            <v-toolbar color="#00000000" class="elevation-0" dark>
              <span style="margin-top:20px;font-size:25px;">{{ $t('landing.fund') }} </span>
              <v-spacer></v-spacer>
            </v-toolbar>
          </v-card-title>

          <v-card-text>
            <v-row>
              <v-col cols="12">
                <span style="margin-top:25px;padding:20px;font-size:15px">I have Crypto Assets</span>
                <v-row @click="openDeposit()" style="padding-left:20px;padding-right:20px;padding-top:20px">
                  <v-col cols="2">
                    <v-icon style="font-size:50px;" color="primary">mdi-bitcoin</v-icon>
                  </v-col>
                  <v-col cols="9">
                    <span>Deposit Crypto</span><br />
                    <span style="font-size:11px">Get the Deposit address for BTC,ETH, or any other crypto and deposit
                      via the
                      blockchain.</span>
                  </v-col>
                  <v-col cols="1">
                    <v-icon color="primary" style="font-size:50px">mdi-menu-right</v-icon>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12">
                <span style="margin-top:25px;padding:20px;font-size:15px">I dont have Crypto Assets</span>
                <v-row @click="goToPageUnavailable()" style="padding-left:20px;padding-right:20px;padding-top:20px">
                  <v-col cols="2">
                    <v-icon style="font-size:50px" color="primary">mdi-account-multiple</v-icon>
                  </v-col>
                  <v-col cols="9">
                    <span>P2P Trading</span><br />
                    <span style="font-size:11px">Buy and Sell cryptocurrencies directly with flexible payment
                      methods</span><br />
                    <span style="font-size:11px">Coming Soon</span>
                  </v-col>
                  <v-col cols="1">
                    <v-icon color="primary" style="font-size:50px">mdi-menu-right</v-icon>
                  </v-col>
                </v-row>

                <v-row @click="goToPageUnavailable()" style="padding-left:20px;padding-right:20px;">
                  <v-col cols="2">
                    <v-icon style="font-size:50px" color="primary">mdi-currency-btc</v-icon>
                  </v-col>
                  <v-col cols="9">
                    <span>Buy Crypto</span><br />
                    <span style="font-size:11px">Buy Crypto Directly with cash, hassle free and suggested for new
                      users</span><br />
                    <span style="font-size:11px">Coming Soon</span>
                  </v-col>
                  <v-col cols="1">
                    <v-icon color="primary" style="font-size:50px">mdi-menu-right</v-icon>
                  </v-col>
                </v-row>
                <!-- 
                <v-row @click="goToPageUnavailable()" style="padding-left:20px;padding-right:20px;">
                  <v-col cols="2">
                    <v-icon style="font-size:50px" color="primary">mdi-bank-circle-outline</v-icon>
                  </v-col>
                  <v-col cols="9">
                    <span>Bank Deposit</span><br />
                    <span style="font-size:11px">Deposit Money (EUR, etc.) from your bank account, and buy crypto with
                      cash
                      balance later.</span><br />
                    <span style="font-size:11px">Coming Soon</span>
                  </v-col>
                  <v-col cols="1">
                    <v-icon color="primary" style="font-size:50px">mdi-menu-right</v-icon>
                  </v-col>
                </v-row> -->

              </v-col>
            </v-row>
          </v-card-text>

        </v-card>
      </v-dialog>

    </v-container>
  </v-app-bar>
</template>

<script>
import axios from 'axios'
export default {
  data: () => ({
    dialog: false,

    menuDialog: false,
    accountMenuDialog: false,

    scrollPosition: 0,
    appBarColor: '#00000000', // Initial color
    appBarHeight: 64, // Initial height

    items: [
      {
        title: 'Markets',
        active: false,
        path: '/markets/prices'
      },

      {
        title: 'Trade',
        active: false,
        path: `/trades/trade-chart/BTC`
      },

      {
        title: 'Futures',
        active: false,
        path: `/trades/futures/BTC?`
      },

      {
        title: 'Earn',
        active: false, // Whether the group is active or not
        action: '', // Icon for the group
        items: [ // Child items
          {
            title: 'Simple Earn',
            path: "/wallet/simple_earn",
          },

          {
            title: 'Auto Invest',
            path: "/wallet/auto_invest",
          },
        ]
      },

      {
        title: 'Deposit',
        active: false,
        path: '/wallet/deposit'
      },

    ],

    accountMenus: [
      {
        title: "Profile",
        icon: "material-symbols:account-circle",
        path: "/profile",
      },

      {
        title: "Fee Rates",
        icon: "mdi-cash",
        path: "/fee-rate",
      },

      {
        title: "Wallet",
        icon: "mdi-wallet-bifold-outline",
        path: "/wallet",
      },

      // {
      //   title: "Tasks Center",
      //   icon: "solar:checklist-bold-duotone",
      //   path: "/account/tasks",
      // },
      {
        title: "Rewards Center",
        icon: "solar:ticket-bold-duotone",
        path: "/account/rewards",
      },
      // {
      //   title: "Pay",
      //   icon: "solar:hand-money-bold-duotone",
      //   path: "/account/pay",
      // },
      {
        title: "Referral",
        icon: "solar:user-plus-bold-duotone",
        path: "/account/referral",
      },
      {
        title: "Security",
        icon: "solar:shield-minimalistic-bold-duotone",
        path: "/account/security",
      },

      {
        title: "Verification",
        icon: "mdi-account-check",
        path: "/verification/overview",
      },


      // {
      //   title: "Clear Cache",
      //   icon: "solar:broom-bold-duotone",
      //   path: "/account/cache",
      // },
    ],
  }),

  computed: {
    isAuthenticated() {
      return this.$store.getters.isAuthenticated;
    },
    client() {
      return this.$store.getters.client;
    },
    user() {
      return this.$store.getters.user;
    },
  },

  methods: {
    navigate(path) {
      this.$router.push(path);
      this.menuDialog = false;
      this.accountMenuDialog = false;
    },

    openDeposit() {
      this.dialog = false
      this.$router.push('/wallet/deposit')
    },

    goToPageUnavailable() {
      this.dialog = false
      if (this.$route.path !== '/page-unavailable') {
        // Using Vue Router to navigate to '/wallet' route
        this.$router.push('/page-unavailable');
      }
    },

    goHome() {
      if (this.$route.path !== '/') {
        // Using Vue Router to navigate to '/wallet' route
        this.$router.push('/');
      }
    },

    walletClicked(route) {
      this.$store.commit("setWalletView", route);
      if (this.$route.path !== '/wallet') {
        // Using Vue Router to navigate to '/wallet' route
        this.$router.push("/wallet");
      }
    },

    goToSection(section) {
      //Route to "/" with #download
      if (this.$route.path == "/")
        window.scrollTo({
          top: document.body.scrollHeight,
          behavior: "smooth",
        });
      else this.$router.push({ path: "/", hash: section });
    },

    logout() {
      this.loading = true;
      this.$store.dispatch("logout");
      axios.post('https://api.gmswap.net/api/v1/logout')
        .then(response => {
          localStorage.clear();
          // Delay the reload to ensure local storage is cleared before redirection
          setTimeout(() => {
            // Reload the page and redirect to sign-in page
            location.href = '/signin';
          }, 500); // Adjust the delay time as needed
        })
        .catch(error => {
          if (error.response && error.response.status === 401) {
            // User is unauthenticated, check if not already on signin route
            if (this.$route.path !== '/signin') {
              try {
                // Redirect to sign-in page
                this.$router.push("/signin");
              } catch (error) {
                // Handle navigation duplicated error
                console.warn('Navigation duplicated error:', error);
              }
            }
          } else {
            // Handle other errors
            console.error('Logout failed:', error);
          }
        })
        .finally(() => {
          // Clear loading state
          this.loading = false;
        });
    },

    handleScroll() {
      this.scrollPosition = window.scrollY;
      this.updateAppBarStyles();
    },

    updateAppBarStyles() {
      const scrollThreshold = 100;
      const minHeight = 64; // Minimum height
      const maxHeight = 100; // Maximum height

      // Update color based on scroll position
      this.appBarColor =
        this.scrollPosition > scrollThreshold ? '#1C1D28' : '#00000000';

      // Update height based on scroll position
      this.appBarHeight =
        minHeight +
        Math.min(this.scrollPosition, scrollThreshold) * (maxHeight - minHeight) / scrollThreshold;
    },

  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },
};
</script>

<style scoped>
.right-dialog {
  right: 0;
  top: 0;
  position: fixed;
  margin: 0;
  transform: translateY(0);
  transform: translateX(0);
  width: 500px
}

.v-toolbar__content,
.v-toolbar__extension {
  padding: 0 !important;
  /* Set padding to 0 */
}

.custom-margin {
  margin-top: 150px;
}

.sticky-appbar {
  /* position: fixed;
    top: 0;
    height: 100px; */
}
</style>
