<template>
  <v-row justify="center">

    <div style="background-color: #fff;">
      <v-row justify="center">
        <v-col cols="12" xs="12" lg="10" xl="10">
          <div class="pa-5">
            <div style="color: #1f2933; font-size: 24px; font-weight: 700;">
              {{ $t('security.securityCenter') }}
            </div>
          </div>
        </v-col>
      </v-row>
    </div>

    <v-col cols="12" xs="12" lg="9">
      <v-card class="mt-5 pa-5" style="border-radius: 3px; border: 1px solid hsla(0, 0%, 100%, .12);">

        <span style="font-size: 24px; font-weight: 600; line-height: 34px;">
          {{ $t('security.twoFactor') }}</span>

        <v-divider></v-divider>

        <div>
          <div class="d-flex align-center justify-space-between" style="border-bottom: 1px solid hsla(0, 0%, 100%, .12);">
            <div class="mt-5">
              <div class="d-flex align-center">
                <v-icon>mdi mdi-account-multiple</v-icon>
                <span class="ml-3" style="font-size: 18px;font-weight: 700;line-height: 24px;">
                  {{ $t('security.accountPassword') }}
                </span>
              </div>
              <p style="font-size: 14px;line-height: 22px;color: #9493ac;">
                {{ $t('security.accDescription') }}
              </p>
            </div>

            <div>
              <v-btn v-if="!$vuetify.breakpoint.xs && !$vuetify.breakpoint.sm" small color="green"
                class="custom-button-width" to="/account/change_password">
                {{ $t('security.manage') }}
              </v-btn>

              <v-btn v-else small icon to="/account/change_password">
                <v-icon>
                  mdi mdi-chevron-right
                </v-icon>
              </v-btn>
            </div>
          </div>

          <div class="d-flex align-center justify-space-between">
            <div class="mt-5">
              <div class="d-flex align-center">
                <v-icon>mdi mdi-account-multiple</v-icon>
                <span class="ml-3" style="font-size: 18px;font-weight: 700;line-height: 24px;">
                  {{ $t('security.transPassword') }}
                </span>
              </div>
              <p style="font-size: 14px;line-height: 22px;color: #9493ac;">
                {{ $t('security.transDescription') }}
              </p>
            </div>

            <div>
              <v-btn v-if="!$vuetify.breakpoint.xs && !$vuetify.breakpoint.sm" small color="green"
                class="custom-button-width" to="/account/change_transaction_password">
                {{ $t('security.manage') }}
              </v-btn>

              <v-btn v-else small icon to="/account/change_transaction_password">
                <v-icon>
                  mdi mdi-chevron-right
                </v-icon>
              </v-btn>
            </div>
          </div>
        </div>
      </v-card>
    </v-col>

    <v-col cols="12" xs="12" lg="9" class="my-10">
      <span style="font-size: 24px; font-weight: 600; line-height: 34px;">
        {{ $t('security.advancedSecurity') }} </span>
      <v-divider></v-divider>
      <div>
        <div class="d-flex align-center justify-space-between" style="border-bottom: 1px solid hsla(0, 0%, 100%, .12);">
          <div class="mt-5">
            <div class="d-flex align-center">
              <v-icon>mdi mdi-account-multiple</v-icon>
              <span class="ml-3" style="font-size: 18px;font-weight: 700;line-height: 24px;">
                {{ $t('security.accountConnections') }}
              </span>
            </div>
            <p style="font-size: 14px;line-height: 22px;color: #9493ac;">
              {{ $t('security.accountDesc') }}
            </p>
          </div>

          <div>
            <v-btn v-if="!$vuetify.breakpoint.xs && !$vuetify.breakpoint.sm" small color="green"
              class="custom-button-width" to="/account/security/account_connections">
              {{ $t('security.manage') }}
            </v-btn>

            <v-btn v-else small icon to="/account/security/account_connections">
              <v-icon>
                mdi mdi-chevron-right
              </v-icon>
            </v-btn>
          </div>
        </div>
      </div>

      <div>
        <div class="d-flex align-center justify-space-between" style="border-bottom: 1px solid hsla(0, 0%, 100%, .12);">
          <div class="mt-5">
            <div class="d-flex align-center">
              <v-icon>mdi mdi-tray-arrow-up</v-icon>
              <span class="ml-3" style="font-size: 18px;font-weight: 700;line-height: 24px;">
                {{ $t('security.cryptoWithdrawal') }}
              </span>
            </div>
            <p class="mt-3" style="font-size: 14px;line-height: 22px;color: #9493ac;">
              {{ $t('security.withdrawalDesc') }}
            </p>
          </div>

          <div>
            <v-btn v-if="!$vuetify.breakpoint.xs && !$vuetify.breakpoint.sm" small color="green"
              class="custom-button-width" @click="oneStepDialog = true">
              {{ $t('security.enable') }}
            </v-btn>

            <v-btn v-else small icon @click="oneStepDialog = true">
              <v-icon>
                mdi mdi-chevron-right
              </v-icon>
            </v-btn>
          </div>
        </div>
      </div>

      <div>
        <div class="d-flex align-center justify-space-between" style="border-bottom: 1px solid hsla(0, 0%, 100%, .12);">
          <div class="mt-5">
            <div class="d-flex align-center">
              <v-icon>mdi mdi-hook-off</v-icon>
              <span class="ml-3" style="font-size: 18px;font-weight: 700;line-height: 24px;">
                {{ $t('security.antiPhising') }}
              </span>
            </div>
            <p class="mt-3" style="font-size: 14px;line-height: 22px;color: #9493ac;">
              {{ $t('security.phishingDesc') }}
            </p>
          </div>

          <div>
            <v-btn v-if="!$vuetify.breakpoint.xs && !$vuetify.breakpoint.sm" small color="green"
              class="custom-button-width" to="/account/security/anti_phishing">
              {{ $t('security.enable') }}
            </v-btn>

            <v-btn v-else small icon to="/account/security/anti_phishing">
              <v-icon>
                mdi mdi-chevron-right
              </v-icon>
            </v-btn>
          </div>
        </div>
      </div>

      <v-row class="mt-5" justify="space-between" align="center">

      
      </v-row>
    </v-col>

    <!-- <v-row>
      <v-col cols="12">
        <v-row class="mt-5 mb-5" justify="center" align="center">
          <v-col cols="6"> Account Password </v-col>

          <v-col cols="6" align="end">
            <v-btn small color="green" @click="accountPasswordDialog = true">
              Manage
            </v-btn>
          </v-col>
        </v-row>
        <v-divider></v-divider>

        <v-row class="mt-5 mb-5" justify="center" align="center">
          <v-col cols="6"> Transaction Password </v-col>

          <v-col cols="6" align="end">
            <v-btn small color="green" @click="transactionPasswordDialog = true">
              Manage
            </v-btn>
          </v-col>
        </v-row>
        <v-divider></v-divider>

        <div class="mt-10 mb-5">
          <h5 class="">Advance Security</h5>
        </div>

        <v-col cols="12">
          <div class="d-flex align-start">
            <div class="mr-5">
              <v-icon>mdi mdi-account-multiple</v-icon>
            </div>

            <div>
              <span style="
                        font-size: 18px;
                        font-weight: 700;
                        line-height: 24px;
                      ">
                Account Connections
              </span>

              <p class="mt-3" style="font-size: 14px; line-height: 22px; color: #9493ac">
                Use a third-party account, such as your Apple ID or Google
                account to log in to your GMSWAP account.
              </p>
            </div>
          </div>

          <div align="end">
            <v-btn small color="green" class="custom-button-width" to="/account/security/account_connections">
              Manage
            </v-btn>
          </div>
        </v-col>

        <v-col cols="12">
          <div class="d-flex align-start">
            <div class="mr-5">
              <v-icon>mdi mdi-tray-arrow-up</v-icon>
            </div>

            <span style="font-size: 18px; font-weight: 700; line-height: 24px">
              Crypto Withdrawal
            </span>
          </div>

          <div class="mt-5" style="padding-left: 3rem">
            <span>One-step Withdrawal</span>
            <p class="mt-3" style="font-size: 14px; line-height: 22px; color: #9493ac">
              When this function is turned on, you can withdraw small
              amount crypto to whitelisted addresses without passing 2FA
              verification
            </p>

            <div align="end">
              <v-btn @click="oneStepDialog = true" small color="green" class="custom-button-width">
                Enable
              </v-btn>
            </div>
          </div>

          <div class="mt-5" style="padding-left: 3rem">
            <span> Withdrawal Whitelist </span>

            <p class="mt-3" style="font-size: 14px; line-height: 22px; color: #9493ac">
              Once this function is enabled, your account will only be
              able to withdraw to addresses on your whitelist. Address
              Management
            </p>
          </div>

          <div align="end">
            <v-btn @click="whitelistlDialog = true" small color="green" class="custom-button-width">
              Enable
            </v-btn>
          </div>
        </v-col>

        <v-col cols="12">
          <div class="d-flex align-start">
            <div class="mr-5">
              <v-icon>mdi mdi-hook-off</v-icon>
            </div>

            <div>
              <span style="
                        font-size: 18px;
                        font-weight: 700;
                        line-height: 24px;
                      ">
                Anti-Phishing Code
              </span>

              <p class="mt-3" style="font-size: 14px; line-height: 22px; color: #9493ac">
                Protect your account from phishing attempts and ensure
                that your notification emails are from GMSWAP only.
              </p>
            </div>
          </div>

          <div align="end">
            <v-btn small color="green" class="custom-button-width" to="/account/security/anti_phishing">
              Enable
            </v-btn>
          </div>
        </v-col>

        <v-col cols="12">
          <div class="d-flex align-start">
            <div class="mr-5">
              <v-icon>mdi mdi-account-details</v-icon>
            </div>

            <div>
              <span style="
                        font-size: 18px;
                        font-weight: 700;
                        line-height: 24px;
                      ">
                App Authorization
              </span>

              <p class="mt-3" style="font-size: 14px; line-height: 22px; color: #9493ac">
                You use your GMSWAP Account to sign in to third party
                sites and apps.
              </p>
            </div>
          </div>

          <div align="end">
            <v-btn small color="green" class="custom-button-width" to="/account/security/app_authorization">
              Manage
            </v-btn>
          </div>
        </v-col>
      </v-col>
    </v-row> -->

    <!-- one step withdrawal -->
    <v-dialog v-model="oneStepDialog" max-width="600px">
      <v-card class="pa-5" color="#272727">
        <v-card-title>
          <span class="text-h5">{{ $t('security.oneStepHeader') }}</span>
        </v-card-title>

        <v-card-text>
          <v-row>
            <v-col cols="12">
              <div>{{ $t('security.oneStepDesc') }}</div>
              <v-radio-group v-model="selectedRadioValue">
                <v-radio v-for="{ id, name, value } in options" :key="id" :label="name" :value="value">
                </v-radio>
              </v-radio-group>
            </v-col>
          </v-row>

          <small>
            {{ $t('security.oneStepDesc2') }}
          </small>
        </v-card-text>

        <v-card-actions>
          <v-row justify="center">
            <v-col cols="6">
              <v-btn @click="oneStepDialog = false" outlined block large>
                {{ $t('dialog.cancel') }}
              </v-btn>
            </v-col>

            <v-col cols="6">
              <v-btn @click="updateWithdrawLimit()" color="primary" class="custom-btn" block large>
                {{ $t('dialog.confirm') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- whitelist withdrawl dialog -->
    <v-dialog v-model="whitelistlDialog" max-width="400px">
      <v-card class="pa-5 d-flex flex-column align-center text-center" color="#1F2339">
        <v-icon> fa-solid fa-triangle-exclamation </v-icon>

        <v-card-title>
          <span class="text-h5">{{ $t('security.enabledWhitelist') }}</span>
        </v-card-title>

        <v-card-text>
          <v-row>
            <v-col cols="12">
              <div>
                {{ $t('security.whitleListDesc') }}
              </div>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-row justify="center">
            <v-col cols="6">
              <v-btn color="#9493AC" class="custom-btn" block large>
                {{ $t('dialog.cancel') }}
              </v-btn>
            </v-col>

            <v-col cols="6">
              <v-btn color="primary" class="custom-btn" block large>
                {{ $t('dialog.enable') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>


    <!-- account password dialog -->
    <v-dialog v-model="accountPasswordDialog" fullscreen hide-overlay transition="dialog-bottom-transition" scrollable>
      <v-card>
        <v-card-title class="mt-5 d-flex align-center justify-space-between">
          <div>{{ $t('security.updateAccPassword') }}</div>

          <v-btn icon dark @click="accountPasswordDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <div>
            <v-form>
              <v-text-field class="mt-5" v-model="oldLoginPassword" outlined placeholder="Old Password (required)"
                :append-icon="visible ? 'mdi-eye-off' : 'mdi-eye'" @click:append="visible = !visible"
                :type="visible ? 'text' : 'password'">
              </v-text-field>

              <v-text-field class="mt-5" v-model="loginPassword" outlined placeholder="New Password"
                :append-icon="visible ? 'mdi-eye-off' : 'mdi-eye'" @click:append="visible = !visible"
                :type="visible ? 'text' : 'password'">
              </v-text-field>

              <v-text-field class="mt-5" v-model="loginPassword_confirmation" outlined placeholder="Confirm Password"
                :append-icon="visible ? 'mdi-eye-off' : 'mdi-eye'" @click:append="visible = !visible"
                :type="visible ? 'text' : 'password'">
              </v-text-field>

              <v-btn @click="confirmPassword()" color="success" class="mt-6" block large>
                {{ $t('security.updatePassword') }}
              </v-btn>
            </v-form>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- transaction password dialog -->
    <v-dialog v-model="transactionPasswordDialog" fullscreen hide-overlay transition="dialog-bottom-transition"
      scrollable>
      <v-card>
        <v-card-title class="mt-5 d-flex align-center justify-space-between">
          <div>{{ $t('security.updateTransPassword') }}</div>
          <v-btn icon dark @click="transactionPasswordDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-form>
            <v-text-field class="mt-5" v-model="oldTransactionPassword" label="Old Password (required)"
              :append-icon="visible ? 'mdi-eye-off' : 'mdi-eye'" @click:append="visible = !visible"
              :type="visible ? 'text' : 'password'">
            </v-text-field>

            <v-text-field class="mt-5" v-model="transactionPassword" label="New Password"
              :append-icon="visible ? 'mdi-eye-off' : 'mdi-eye'" @click:append="visible = !visible"
              :type="visible ? 'text' : 'password'">
            </v-text-field>

            <v-text-field class="mt-5" v-model="transactionPassword_confirmation" label="Confirm Password"
              :append-icon="visible ? 'mdi-eye-off' : 'mdi-eye'" @click:append="visible = !visible"
              :type="visible ? 'text' : 'password'">
            </v-text-field>

            <v-btn @click="confirmTransactionPassword()" color="primary" class="mt-6" block x-large>
              {{ $t('security.newPassword') }}
            </v-btn>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
export default {
  data: () => ({
    accountPasswordDialog: false,
    transactionPasswordDialog: false,
    oneStepDialog: false,
    whitelistlDialog: false,
    loading: false,
    selectedRadioValue: 500,
    options: [
      { id: "1", name: '500', value: 500 },
      { id: "2", name: '1000', value: 1000 },
      { id: "3", name: '1500', value: 1500 }
    ],

    items: [
      {
        title: "Change Password",
        to: "change_password",
      },
      {
        title: "Change Transaction Password",
        to: "change_transaction_password",
      },
      {
        title: "Verify Account",
        to: "verify",
      },
    ],
  }),
  methods: {
    // Add swal
    updateWithdrawLimit() {
      // console.log(this.withdraw_limit)
      this.$axios.get('https://api.gmswap.net/api/v1/update-withdraw-limit', {
        params: {
          withdraw_limit: this.selectedRadioValue
        }
      })
        .then(response => {
          this.$swal({
            toast: true,
            position: "top-end",
            icon: "success",
            title: "Withdraw Limit Saved",
            showConfirmButton: false,
            timer: 3000, // milliseconds
          });
        })
    }
  },
};
</script>
<style>
/* Position the logout button at the bottom right corner */
.v-btn--absolute {
  position: fixed;
  bottom: 16px;
  right: 16px;
}

/* Button Spinner */
.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}

@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@-o-keyframes loader {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes loader {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}
</style>
