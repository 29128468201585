<template>
    <div>
        <v-card v-if="!$vuetify.breakpoint.xs" class="pa-3 my-3" color="#272727" style="border-radius: 10px;">
            <v-card-title class="d-flex align-center justify-space-between">
                <div>
                    <v-avatar size="25" color="white">
                        <img :src="image" :alt="name" />
                    </v-avatar>
                    <span class="ml-4">{{ name }}</span>
                </div>

                <div class="d-flex flex-column">
                    <span class="regular greenPrimary--text">{{ apr + "%" }}</span>
                    <span class="subtitle">Est APR</span>
                </div>
            </v-card-title>
            <v-divider style="border: 1px solid #b7bdc6;"></v-divider>
            <v-card-actions class="mt-3">
                <v-row justify="center" no-gutter>
                    <v-col class="mb-1">
                        <v-btn color="primary" style="color:#2b3139; border-radius: 10px" block
                            @click="openDetails()">Subscribe
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-actions>
        </v-card>

        <!-- if mobile -->
        <v-sheet v-if="$vuetify.breakpoint.xs" @click="openDetails()" color="#00000000" class="mt-2 pa-4"
            style="border: 0.1px solid #b7bdc6; border-radius: 10px;">
            <div class="d-flex align-center justify-space-between">
                <div>
                    <v-avatar size="25">
                        <img :src="image" :alt="name" />
                    </v-avatar>

                    <span class="ml-2">{{ name }}</span>
                </div>
                <div class="d-flex flex-column">
                    <span class="value-percent ">{{ apr + "%" }}</span>
                    <span class="subtitle">Est APR</span>
                </div>
            </div>
        </v-sheet>

        <v-dialog v-if="this.apr != 'Not available'" v-model="detailsDialog" :fullscreen="$vuetify.breakpoint.smAndDown"
            width="500" transition="transitionClass">
            <v-card color="#272727">

                <v-toolbar elevation="0" class="px-5">
                    <!-- <v-btn small icon @click="detailsDialog = false">
                        <v-icon>mdi mdi-close</v-icon>
                    </v-btn> -->
                    <v-toolbar-title>Create a plan</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn small icon @click="detailsDialog = false">
                        <v-icon>mdi mdi-close</v-icon>
                    </v-btn>
                </v-toolbar>

                <v-card-text class="mt-5" style="overflow-x: hidden;">
                    <v-form ref="earnForm" lazy-loading>

                        <div class="d-flex justify-content-start align-items-center mb-2">
                            <v-avatar size="25">
                                <img :src="image" :alt="name" />
                            </v-avatar>
                            <h5 class="ml-3 mb-0 white--text">
                                {{ name }}
                            </h5>
                        </div>

                        <div class="d-flex justify-content-between">
                            <label class="mb-3">Amount</label>
                            <label class="success--text font-weight-bold">Available: 12312
                            </label>
                        </div>

                        <v-col cols="12" class="pa-0">
                            <v-text-field dense outlined required
                                onkeypress="return (event.charCode >= 48 && event.charCode <= 57) || event.charCode == 46"
                                v-model="amount" :rules="rules()"></v-text-field>
                        </v-col>

                        <div class="my-3">
                            <span style="font-size: 16px; font-weight: 500;">
                                Use Simple Earn Flexible
                                Balance?
                            </span>
                            <p class="sub-title1 mb-0 grey--text">
                                Assets are redeemed from Simple Earn
                                Flexible Balance and used towards
                                Auto-Invest when the spot balance is
                                insufficient.
                            </p>
                        </div>

                        <v-row>
                            <v-col cols="12">

                                <div>
                                    <label class="mb-3 ">Recuring Cycle</label>
                                    <v-item-group mandatory v-model="selectedCycle">
                                        <v-row>
                                            <v-col v-for="cycle in cycleOptions" :key="cycle" cols="3" class="pa-1">
                                                <v-item :value="cycle" v-slot="{ active, toggle }">
                                                    <v-card :color="active ? 'primary' : '#b7bdc6'"
                                                        class="d-flex align-center" dark height="30" @click="toggle">
                                                        <v-scroll-y-transition>
                                                            <div class="flex-grow-1 text-center"
                                                                :style="{ color: active ? '#000000' : '' }"
                                                                style="font-weight:500;">
                                                                {{ cycle }}
                                                            </div>
                                                        </v-scroll-y-transition>
                                                    </v-card>
                                                </v-item>
                                            </v-col>
                                        </v-row>
                                    </v-item-group>
                                </div>

                                <div v-if="showDayOptions">
                                    <label class="mt-4 mb-3">Repeats On</label>
                                    <v-item-group mandatory v-model="day">
                                        <v-row>
                                            <v-col v-for="day in dayOptions" :key="day" cols="3" class="pa-1">
                                                <v-item :value="day" v-slot="{ active, toggle }">
                                                    <v-card :color="active ? 'primary' : '#b7bdc6'"
                                                        class="d-flex align-center" dark height="30" @click="toggle">
                                                        <v-scroll-y-transition>
                                                            <div class="flex-grow-1 text-center"
                                                                :style="{ color: active ? '#000000' : '' }"
                                                                style="font-weight:500;">
                                                                {{ day }}
                                                            </div>
                                                        </v-scroll-y-transition>
                                                    </v-card>
                                                </v-item>
                                            </v-col>
                                        </v-row>
                                    </v-item-group>
                                </div>

                                <div class="mt-4 mb-3" v-if="selectedCycle == 'Monthly'">
                                    <label class="">Repeats on date of the
                                        month</label>
                                    <v-col>
                                        <v-select solo :items="dateOptions" v-model="date">
                                        </v-select>
                                    </v-col>
                                </div>

                                <div class="my-5">
                                    <label class="mb-2">Repeat on local
                                        time</label>

                                    <v-dialog ref="timeDialog" v-model="timeModal" :return-value.sync="time" persistent
                                        width="290px">

                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field v-model="time" label="Time" readonly solo height="28"
                                                v-bind="attrs" v-on="on"></v-text-field>
                                        </template>

                                        <v-time-picker v-if="timeModal" v-model="time" full-width>
                                            <v-spacer></v-spacer>
                                            <v-btn text color="primary" @click="timeModal = false">
                                                Cancel
                                            </v-btn>
                                            <v-btn text color="primary" @click="
                                                $refs.timeDialog.save(
                                                    time
                                                )
                                                ">
                                                OK
                                            </v-btn>
                                        </v-time-picker>

                                    </v-dialog>
                                </div>

                                <div>
                                    <label>Summary</label>
                                    <v-card>
                                        <v-card-text>
                                            <v-timeline align-top dense>
                                                <v-timeline-item color="secondary" small>
                                                    <v-row class="pt-1">
                                                        <v-col>
                                                            <span class="item-label">
                                                                Create Time
                                                            </span>
                                                        </v-col>
                                                        <v-col cols="5">
                                                            <span class="item-value">{{ createTime }}</span>
                                                        </v-col>
                                                    </v-row>
                                                </v-timeline-item>
                                                <v-timeline-item color="greenPrimary" hide-dot>
                                                    <v-row class="pt-1">
                                                        <v-col>
                                                            <span class="item-label">
                                                                Amount Per Period
                                                            </span>
                                                        </v-col>
                                                        <v-col cols="5">
                                                            <strong>--</strong>
                                                        </v-col>
                                                    </v-row>
                                                </v-timeline-item>
                                                <v-timeline-item color="greenPrimary" small>
                                                    <v-row class="pt-1">
                                                        <v-col>
                                                            <span class="item-label">
                                                                First Auto-Invest
                                                                Date
                                                            </span>
                                                        </v-col>
                                                        <v-col cols="5">
                                                            <span class="item-value">{{ firstAutoInvestDate }}</span>
                                                        </v-col>
                                                    </v-row>
                                                </v-timeline-item>
                                            </v-timeline>
                                        </v-card-text>
                                    </v-card>
                                </div>

                                <div class="mt-5">
                                    <v-btn block large color="primary" style="color: #2b3139;"
                                        @click="invest('STAKE_LOCK')">Confirm
                                    </v-btn>
                                </div>

                            </v-col>

                        </v-row>
                    </v-form>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
export default {
    props: {
        id: String,
        name: String,
        image: String,
        apr: String,
        earn: Array,
    },
    data() {
        return {
            detailsDialog: false,
            timeModal: false,
            time: null,
            selectedCycle: "Daily",
            day: "Mon",
            date: "1",
            dayOptions: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
            dateOptions: [
                "1",
                "2",
                "3",
                "4",
                "5",
                "6",
                "7",
                "8",
                "9",
                "10",
                "11",
                "12",
                "13",
                "14",
                "15",
                "16",
                "17",
                "18",
                "19",
                "20",
                "21",
                "22",
                "23",
                "24",
                "25",
                "26",
                "27",
                "28",
                "29",
                "30",
                "31",
            ],
            cycleOptions: [
                "Daily",
                "Weekly",
                "Bi-Weekly",
                "Monthly",
                "1 Hour",
                "4 Hours",
                "8 Hours",
                "12 Hours",
            ],
            amount: 0,
            balance: 0,
            totalQuota: 0,
        };
    },
    computed: {
        transitionClass() {
            return $vuetify.breakpoint.smAndDown ? 'dialog-bottom-transition' : '';
        },

        percentColor() {
            //Return the color based on the percent value if positive or negative
            return this.apr != "Not available"
                ? "percent-card greenPrimary--text d-flex align-center justify-center transparent elevation-0"
                : "percent-card redPrimary--text d-flex align-center justify-center transparent elevation-0";
        },
        percentWithSign() {
            //Return the percent value with a sign
            return this.apr != "Not available"
                ? "APR " + this.apr + "%"
                : "Not Available";
        },
        minimum() {
            return parseFloat(this.earn[0].minimum_amount);
        },
        maximum() {
            return parseFloat(this.earn[0].maximum_amount);
        },
        createTime() {
            return new Date().toLocaleString();
        },
        firstAutoInvestDate() {
            const currentDate = new Date();
            let first = new Date(); // Adding 1 day in milliseconds
            switch (this.selectedCycle) {
                case "Daily":
                    first = new Date(
                        currentDate.getTime() + 24 * 60 * 60 * 1000
                    ); //
                    first.setHours(0, 0, 0, 0);
                    break;
                case "Weekly":
                    first = new Date(
                        currentDate.getTime() + 7 * 24 * 60 * 60 * 1000
                    ); //
                    first.setHours(0, 0, 0, 0);
                    break;
                case "Bi-Weekly":
                    first = new Date(
                        currentDate.getTime() + 14 * 24 * 60 * 60 * 1000
                    ); //
                    first.setHours(0, 0, 0, 0);
                    break;
                case "Monthly":
                    first = new Date(
                        currentDate.getTime() + 30 * 24 * 60 * 60 * 1000
                    ); //
                    first.setHours(0, 0, 0, 0);
                    break;
                case "1 Hour":
                    first = new Date(
                        currentDate.getTime() + 1 * 60 * 60 * 1000
                    ); //
                    break;
                case "4 Hours":
                    first = new Date(
                        currentDate.getTime() + 4 * 60 * 60 * 1000
                    ); //
                    break;
                case "8 Hours":
                    first = new Date(
                        currentDate.getTime() + 8 * 60 * 60 * 1000
                    ); //
                    break;
                case "12 Hours":
                    first = new Date(
                        currentDate.getTime() + 12 * 60 * 60 * 1000
                    ); //
                    break;
            } // Adding 1 day in milliseconds
            return first.toLocaleString();
        },
        showDayOptions() {
            return (
                this.selectedCycle == "Weekly" ||
                this.selectedCycle == "Bi-Weekly"
            );
        },
    },
    methods: {
        rules() {
            return [
                (v) => !!v || "Amount is required",
                (v) =>
                    v >= this.minimum ||
                    "Amount must be greater than or equal minimum",
                (v) => v <= this.maximum || "Amount must be less than maximum",
                (v) =>
                    v <= this.balance ||
                    "Amount must be less than or equal balance",
            ];
        },
        maxAmount() {
            this.amount = this.balance;
        },
        openDetails() {
            this.$axios
                .get("https://api.gmswap.net/api/v1/wallet-balance", {
                    params: {
                        name: this.name,
                    },
                })
                .then((response) => {
                    this.balance = response.data.wallet.wallet_balance;
                    if (this.apr != "Not available") this.detailsDialog = true;
                });
        },
        invest(type) {
            if (this.$refs.earnForm.validate()) {
                this.$axios
                    .post("https://api.gmswap.net/api/v2/earn/store", {
                        earn_id: this.earn[0].id,
                        cycles: this.selectedCycle,
                        amount: this.amount,
                        status: type,
                    })
                    .then((response) => {
                        if (response.data.message == "success") {
                            this.$swal({
                                title: "Success",
                                text: "Investment successful",
                                icon: "success",
                                timer: 3000,
                                buttons: false,
                            });
                            this.$store.dispatch("setWallet");
                            this.$router.push("/home");
                        } else {
                            this.$swal({
                                title: "Error",
                                text: "Something went wrong",
                                icon: "error",
                                timer: 3000,
                                buttons: false,
                            });
                        }
                    });
            }
        },
    },
};
</script>
<style>
.percent-card {
    font-weight: 550;
}

.v-timeline::before {
    top: 55px;
    height: calc(100% - 110px);
}

.item-value {
    font-size: 14px;
    font-weight: 500;
}

.item-label {
    font-size: 14px;
    font-weight: 500;
    color: #b7bdc6;
}
</style>
