import "bootstrap/dist/css/bootstrap.min.css";
import Vue from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import vuetify from "./plugins/vuetify";
import VueTelInputVuetify from "vue-tel-input-vuetify";
import Echo from "laravel-echo";
import Pusher from 'pusher-js';
import "./plugins";
import i18n from "./i18n"

// Register Components
import "./components";

Vue.config.productionTip = false;

Vue.use(VueTelInputVuetify, {
    vuetify,
});

new Vue({
    store,
    router,
    vuetify,
    i18n,

    mounted() {
        // Log breakpoints
        console.log('Breakpoints:', this.$vuetify.breakpoint);
    },

    render: (h) => h(App),
}).$mount("#app");


import "bootstrap/dist/js/bootstrap.js";

