<template>
    <v-container>
        <v-row class="mt-10" justify="center" align="center">
            <v-col cols="12" xs="12" lg="4" class="text-center">
                <div class="text-center">
                    <img class="" src="@/assets/images/gmswap-logo.png" style="max-width: 120px; max-height: 120px;" />
                    <!-- <span>GMSwap</span> -->
                </div>
                <h4>
                    This Service is not yet available in your Country <v-icon>mdi mdi-earth-off</v-icon>
                </h4>

                <div class="mt-5">
                    <ButtonComponent :onClick="goToWallet" style="color: #1C1D28 ; letter-spacing: 0;" color="primary"
                        label="Go back to Wallet" />
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import ButtonComponent from '@/components/ui/ButtonComponent.vue';

export default {
    components: {
        ButtonComponent,
    },

    methods: {
        goToWallet() {
            this.$router.push('/wallet');
        }
    }
}
</script>