<template>
  <div v-if="!loading">
    <v-row class="price-list">
      <v-col cols="6" class="text-left">
        <span class="greenPrimary--text" v-for="(bid, index) in orders.bids" :key="index"><br />
          {{ bid.unit_price }}</span>
      </v-col>
      <v-col cols="6" class="text-end">
        <span class="white--text" v-for="(bid, index) in orders.bids" :key="index"><br />
          {{ bid.number_of_order }}</span>
      </v-col>
    </v-row>
  </div>
  <div v-else>
    <v-card class="pa-2 mt-4">
      <v-skeleton-loader type="list-item-three-line" class="mx-auto" :loading="loading"></v-skeleton-loader>
    </v-card>
    <v-card class="pa-2 mt-4">
      <v-skeleton-loader type="list-item-three-line" class="mx-auto" :loading="loading"></v-skeleton-loader>
    </v-card>
  </div>
</template>
<script>
export default {
  data: () => ({
    loading: true,
  }),
  props: {
    selectedCoin: {
      type: String,
      required: true,
    },
  },
  computed: {
    orders: function () {
      return this.$store.state.orders;
    },
    watch: {
      selectedCoin: function () {
        clearInterval(this.interval);
        this.$store.commit("setOrders", []);
        this.fetchOrders();
        this.interval = setInterval(() => {
          this.fetchOrders();
        }, 10000);
      },
    },
    methods: {
      async fetchOrders() {
        try {
          this.$axios
            .get(`https://api.gmswap.net/api/v2/recent-trades`, {
              params: {
                name: this.selectedCoin + "USDT",
              },
            })
            .then((response) => {
              //Save Orders to vuex
              this.$store.commit("setOrders", response.data);
              this.loading = false;
            });
        } catch (error) {
          console.log(error);
          return [];
        }
      },
    },
    mounted() {
      setTimeout(() => {
        this.loading = false;
      }, 1000);
      this.fetchOrders();
      this.interval = setInterval(() => {
        this.fetchOrders();
      }, 10000);
    },
  },
  methods: {
    async fetchOrders() {
      try {
        this.$axios
          .get(`https://api.gmswap.net/api/v2/recent-trades`, {
            params: {
              name: this.selectedCoin + "USDT",
            },
          })
          .then((response) => {
            //Save Orders to vuex
            this.$store.commit("setOrders", response.data);
            this.loading = false;
          });
      } catch (error) {
        console.log(error);
        return [];
      }
    },
  },
  mounted() {
    setTimeout(() => {
      this.loading = false;
    }, 1000);
    this.fetchOrders();
    this.interval = setInterval(() => {
      this.fetchOrders();
    }, 10000);
  },
  //Remove/Clear interval and data when component is destroyed
  beforeDestroy() {
    clearInterval(this.interval);
    this.$store.commit("setOrders", []);
  },
};
</script>
<style>
.price-list {
  font-size: 11px;
  color: #b3b3b3;
}

.list-title {
  font-size: 13px;
}

.sub-caption {
  font-size: 10px;
  color: #b3b3b3;
}
</style>