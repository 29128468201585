<template>
  <v-row>

    <v-col cols="12">
      <div class="d-flex align-center justify-space-between">

        <div class="d-flex align-center">
          <h4 @click="openDialog()" class="font-weight-bold mb-0" style="cursor: pointer">
            {{ selectedCoin }}USDT
          </h4>
          <span style="margin-left:10px;font-size:15px;font-weight:300px; cursor: pointer;">{{ selectedCoin }}</span>
          <v-icon style="margin-left:3px;" v-if="selectedMarketCoin.percent > 0"
            color="#089827">mdi-arrow-top-right-thin</v-icon>
          <v-icon style="margin-left:3px;" v-else-if="selectedMarketCoin.percent < 0"
            color="#CD4B39">mdi-arrow-top-right-thin</v-icon>

          <v-chip style="margin-left:3px;" v-if="selectedMarketCoin.percent > 0" color="#089827">{{
            parseFloat(selectedMarketCoin.percent).toFixed(2) }}%</v-chip>
          <v-chip style="margin-left:3px;" v-else-if="selectedMarketCoin.percent < 0" color="CD4B39">{{
            parseFloat(selectedMarketCoin.percent).toFixed(2) }}%</v-chip>
        </div>

        <div v-if="!$vuetify.breakpoint.xs">
          <v-btn text @click="openChart()"><v-icon>mdi-chart-arc</v-icon>CHART</v-btn>
          <v-btn text to="/markets/trading_data"><v-icon>mdi-poll</v-icon>MARKET</v-btn>
        </div>
      </div>
    </v-col>

    <v-col cols="12" v-if="$vuetify.breakpoint.xs">
      <v-btn class="mr-3" small outlined to="/trades/trade-chart/BTC"><v-icon>mdi-chart-arc</v-icon>Chart</v-btn>
      <v-btn small outlined to="/markets/trading_data"><v-icon>mdi-poll</v-icon>Market</v-btn>
    </v-col>

    <!-- <v-col cols="12" xs="12" sm="12" md="7" lg="7">
      <v-form ref="tradeForm" lazy-loading>
        <v-row style="margin-top:5px">
          <v-col cols="12" class="mb-0">

            <v-btn-toggle v-model="delegateType" color="transparent" mandatory class="full-width-toggle"
              @change="setAmountPercentage">
              <v-btn v-for="(type, index) in delegateTypes" :key="index" :value="type" large :class="delegateType === type
            ? 'white--text'
            : 'grey--text'
            " :color="delegateType === type
            ? delegateColor
            : 'greyPrimary'
            ">
                {{ type }}
              </v-btn>
            </v-btn-toggle>

          </v-col>

          <v-col cols="12" class="mt-2 py-1">
            <v-text-field disabled label="Market Price" underlined></v-text-field>
          </v-col>

          <v-col cols="12" class="mt-0 py-1">
            <v-select :items="['x10', 'x15', 'x20']" v-model="leverage" undelined class="centered-input"
              label="Leverage"></v-select>
          </v-col>

          <v-col v-if="!$vuetify.breakpoint.xs" cols="12" class="d-flex align-center justify-space-between">
            <div>Amount</div>
            <v-btn-toggle v-model="amountPercentage" mandatory @input="setAmountPercentage">
              <v-btn small text>25%</v-btn>
              <v-btn small text>50%</v-btn>
              <v-btn small text>75%</v-btn>
              <v-btn small text>100%</v-btn>
            </v-btn-toggle>
          </v-col>

          <v-col v-else cols="12">
            <div>Amount</div>
            <v-btn-toggle v-model="amountPercentage" mandatory @input="setAmountPercentage">
              <v-btn small text>25%</v-btn>
              <v-btn small text>50%</v-btn>
              <v-btn small text>75%</v-btn>
              <v-btn small text>100%</v-btn>
            </v-btn-toggle>
          </v-col>

          <v-col cols="12" class="mt-0 py-1">
            <v-text-field v-model="amount" prepend-inner-icon="mdi-minus" append-icon="mdi-plus"
              :label="priceType === 'COIN' ? selectedCoin : 'USDT'" :rules="amountRules()">
            </v-text-field>
          </v-col>

          <div class="d-flex align-center justify-space-between">
            <label
              :class="balanceToDisplay <= 0 ? 'caption error--text font-weight-thin' : 'caption success--text font-weight-thin'">
              Available:
              <span>{{ balanceToDisplay | commaFormat }}</span>
            </label>
          </div>

          <v-col cols="12" class="mt-3 py-1">
            <v-btn block large :color="delegateColor" @click="trade">
              <span class="text-capitalize">{{
            delegateType === "BUY MORE"
              ? "BUY"
              : "SELL"
          }}</span>
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-col> -->

    <v-col v-if="!$vuetify.breakpoint.xs" cols="12" xs="12" lg="6">
      <v-form ref="tradeForm" lazy-loading>
        <v-card class="pa-8" color="#00000000" style="border-radius: 20px; border: 1px solid #9493AC">

          <div>
            <v-select :items="['MARKET', 'LIMIT']" v-model="marketType" underlined class="centered-input">
            </v-select>
          </div>

          <div class="mt-2">
            <v-text-field disabled label="Market Price" underlined>
            </v-text-field>
          </div>

          <div class="mt-2 d-flex align-center justify-space-between">
            <span>Amount</span>

            <v-rating hover length="4" v-model="amountPercentage" class="rating-flex" @input="setAmountPercentage">
              <template v-slot:item="props">
                <div class="icon-with-label">
                  <v-icon size="25" :color="props.isFilled ? 'greenPrimary' : 'grey lighten-1'
                    " class="py-0" @click="props.click">
                    {{
                      props.isFilled
                      ? "mdi-rectangle"
                      : "mdi-rectangle-outline"
                    }}
                  </v-icon>
                  <span class="rating-label">{{
                    ratingText(props.index)
                  }}</span>
                </div>
              </template>
            </v-rating>
          </div>

          <div class="mt-2">
            <v-text-field label="Enter Amount" v-model="amount" underlined :rules="amountRules()">
            </v-text-field>
          </div>


          <div class="mt-2 d-flex align-center justify-space-between">
            <span>Available</span>
            <span>{{ balanceToDisplay }}</span>
          </div>

          <div class="mt-5">
            <v-btn block medium color="#089827" @click="confirm" :rules="amountRules()">
              BUY
            </v-btn>
          </div>

        </v-card>
      </v-form>
    </v-col>

    <v-col v-if="!$vuetify.breakpoint.xs" cols="12" xs="12" lg="6">
      <v-form ref="tradeForm" lazy-loading>
        <v-card class="pa-8" color="#00000000" style="border-radius: 20px; border: 1px solid #9493AC">

          <div>
            <v-select :items="['MARKET', 'LIMIT']" v-model="sellMarketType" underlined class="centered-input"></v-select>
          </div>

          <div class="mt-2">
            <v-text-field disabled label="Market Price" undelined></v-text-field>
          </div>

          <div class="mt-2 d-flex align-center justify-space-between">
            <span>Amount</span>

            <v-rating hover length="4" v-model="sellAmountPercentage" class="rating-flex"
              @input="setSellAmountPercentage">
              <template v-slot:item="props">
                <div class="icon-with-label">
                  <v-icon size="25" :color="props.isFilled ? 'greenPrimary' : 'grey lighten-1'
                    " class="py-0" @click="props.click">
                    {{
                      props.isFilled
                      ? "mdi-rectangle"
                      : "mdi-rectangle-outline"
                    }}
                  </v-icon>
                  <span class="rating-label">{{
                    ratingText(props.index)
                  }}</span>
                </div>
              </template>
            </v-rating>

          </div>

          <div class="mt-2">
            <v-text-field label="Enter Amount" undelined v-model="sellAmount" :rules="sellAmountRules()">
            </v-text-field>
          </div>

          <div class="mt-2 d-flex align-center justify-space-between">
            <span>Available</span>
            <span>{{ sellBalanceToDisplay }}</span>
          </div>

          <div class="mt-5">
            <v-btn block medium color="red" @click="confirmSell">
              SELL
            </v-btn>
          </div>
        </v-card>
      </v-form>
    </v-col>

    <v-col cols="12">
      <v-card class="pa-8" color="#00000000" style="border-radius: 20px; border: 1px solid #9493AC">
        <v-row>
          <v-col cols="12" xs="12" lg="4">
            <v-row>
              <v-col cols="6">
                Price: {{ orders.price }}
              </v-col>
              <v-col cols="6">
                Amount: {{ orders.price }}
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" xs="12" lg="4">
            <main-trades-bids :selectedCoin="selectedCoin" />
          </v-col>
          <v-col cols="12" xs=12 lg="4">
            <main-trades-orders :selectedCoin="selectedCoin" />
          </v-col>
        </v-row>
      </v-card>
    </v-col>

    <v-col cols="12">
      <v-tabs align-tabs="end" v-model="tab" background-color="#00000000" bg-color="transparent" class="tabs-right">
        <v-tab>Funds</v-tab>
        <v-tab-item>
          <v-card class="pa-5 mt-5" color="#00000000" style="border-radius: 20px; border: 1px solid #9493AC">
            <v-data-table :headers="headers" :items="funds" :items-per-page="10" class="transparent">
              <!-- <template v-slot:item.price="{ item }">
                  {{ parseFloat(item.final_price).toFixed(2) }}
                </template> -->
            </v-data-table>
          </v-card>

        </v-tab-item>

        <v-tab>Open Orders</v-tab>
        <v-tab-item>
          <v-card class="pa-5 mt-5" color="#00000000" style="border-radius: 20px; border: 1px solid #9493AC">
            <v-data-table :headers="headers" :items="funds" :items-per-page="10" class="transparent">
              <!-- <template v-slot:item.price="{ item }">
                {{ parseFloat(item.final_price).toFixed(2) }}
              </template> -->
            </v-data-table>
          </v-card>
        </v-tab-item>

      </v-tabs>
    </v-col>

    <v-dialog v-model="confirmDialog" persistent width="400px">
      <v-card width="400px">
        <v-card-title>
          <v-toolbar class="elevation-0 transparent">
            <v-spacer></v-spacer>
            <v-toolbar-title>Confirmation</v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
        </v-card-title>
        <v-card-text>
          <v-form ref="confirmationForm" lazy-loading>
            <v-row>
              <v-col cols="12">
                <label>Transaction Password</label>
                <v-text-field v-model="password" background-color="#29313e"
                  :append-icon="visible ? 'mdi-eye-off' : 'mdi-eye'" @click:append="visible = !visible"
                  :rules="passwordRules()" :type="visible ? 'text' : 'password'" :error-messages="errorMessage.password"
                  dense />
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" large text @click="confirmDialog = false">
            Cancel
          </v-btn>
          <v-btn color="primary" class="px-4" large rounded @click="trade(), (loader = 'loading')" :loading="loading"
            :disabled="loading">
            Confirm
            <template v-slot:loader>
              <span class="custom-loader">
                <v-icon light>mdi-cached</v-icon>
              </span>
            </template>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="coinsDialog" max-width="0" class="elevation-0" color="#00000000">
      <v-card class="right-dialog pa-5" style="height:100vh">
        <v-row align="center">
          <v-col cols="12">
            <v-text-field placeholder="Search Coin" append-icon="mdi-magnify"></v-text-field>
            <v-data-table :headers="headers" :items="powPairs" :hide-default-footer="false" :items-per-page="10"
              class="transparent" @click:row="rowClicked($event)">
              <template v-slot:item.name="{ item }">
                <v-row>
                  <v-col cols="3" class="text-center" style="margin-top:2px">
                    <v-avatar size="30">
                      <v-img :src="item.image"></v-img>
                    </v-avatar>
                  </v-col>
                  <v-col cols="9">
                    <span style="margin-top:50px">{{ item.coin_pair_name }}</span><br />
                    <span style="font-size:10px;color:grey">{{ (item.coin_pair_name).replace('USDT', '') }}</span>
                    <!-- <span> {{ item.date }}</span><br/> -->
                  </v-col>
                </v-row>
              </template>
              <template v-slot:item.close="{ item }">
                <v-row>
                  <v-col cols="12">
                    <span>${{ parseFloat(item.close).toFixed(3) }}</span><br />
                    <!-- <span style="font-size:10px;color:grey">{{ (item.coin_pair_name).replace('USDT', '')}}</span> -->
                    <!-- <span> {{ item.date }}</span><br/> -->
                  </v-col>
                </v-row>
              </template>

              <template v-slot:item.increase_in_value="{ item }">

                <span v-if="item.increase_in_value > 0" style="color:green">{{
                  parseFloat(item.increase_in_value).toFixed(4) }}% <v-icon style="color:green"
                    class="ml-0">mdi-arrow-top-right-thin</v-icon></span>
                <span v-if="item.increase_in_value < 0" style="color:red">{{
                  parseFloat(item.increase_in_value).toFixed(4) }}% <v-icon style="color:red"
                    class="ml-0">mdi-arrow-bottom-right-thin</v-icon></span>

              </template>
              <template v-slot:item.volume="{ item }">
                <span>{{ parseFloat(item.volume).toFixed(2) }}</span><br />
              </template>
            </v-data-table>
          </v-col>
        </v-row>

        <v-col cols="12">

        </v-col>

      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import axios from 'axios'
import store from "@/store";
import TVChartContainer from '../../../components/TVChartContainer.vue';
import formattedPrice from '../../../utils/formatter'

export default {
  components: {
    TVChartContainer,
  },
  name: "trades",
  data: () => ({
    headers: [
      {
        value: "name",
        text: "Name",
        align: "start",
        sortable: true,
      },
      {
        value: "close",
        text: "Price",
        sortable: true,
        align: "start",
      },
      {
        value: "increase_in_value",
        text: "24h Change",
        sortable: true,
        align: "center",
      },
    ],
    priceLimit: '',
    coinsDialog: false,
    powPairs: [],
    navSelectedCoin: 0,
    loading: false,
    visible: false,
    marketCoinsDialog: false,
    delegateTypes: ["BUY", "SELL"],
    marketType: "MARKET",
    sellMarketType: "MARKET",
    priceTypes: ["AMOUNT", "TOTAL"],
    priceType: "AMOUNT",
    amountPercentage: 0,
    sellAmountPercentage: 0,
    amount: null,
    sellAmount: null,
    password: null,
    minimum: {
      minimum_sell: 0,
      account_balance_limit_spot: 0,
    },
    errorMessage: {
      password: "",
    },
    dataRaw: {
      dates: [],
      series: [],
      current: {},
    },
    timeFrame: "1min",
    step: 1,
    coinDetails: {
      open: 0,
      close: 0,
      high: 0,
      low: 0,
      volume: 0,
    },
    latestDetails: {
      amount: 0,
      sellAmount: 0,
      percent: 0,
    },
    previousPath: "",
    confirmDialog: false,
  }),

  computed: {

    isAuthenticated() {
      return this.$store.getters.isAuthenticated;
    },

    client() {
      return this.$store.getters.client;
    },
    delegateType: {
      get() {
        return this.$store.state.delegateType;
      },
      set(value) {
        this.$store.state.delegateType = value;
      },
    },
    selectedCoin: {
      get() {
        return this.$store.state.selectedCoin;
      },
      set(value) {
        this.$store.state.selectedCoin = value;
      },
    },
    selectedMarketCoin() {
      return this.$store.getters.marketCoins.find(
        (coin) => coin.name == this.selectedCoin
      );
    },
    marketCoins() {
      return this.$store.getters.marketCoins;
    },
    coins() {
      //Get name only
      return this.$store.getters.marketCoins.map((coin) => coin.name);
    },
    numberOfOrder() {
      if (this.delegateType == 'BUY') {
        if (this.priceType === "AMOUNT") {
          return this.amount / this.valuePerUnit;
        } else {
          return this.amount;
        }
      }
      else if (this.delegateType == 'SELL') {
        if (this.priceType === "AMOUNT") {
          return this.sellAmount / this.valuePerUnit;
        } else {
          return this.sellAmount;
        }
      }

    },
    total() {
      if (this.delegateType == 'BUY') {
        if (this.priceType === "AMOUNT") {
          return this.amount;
        } else {
          return this.amount * this.valuePerUnit;
        }
      }
      else if (this.delegateType == 'SELL') {
        if (this.priceType === "AMOUNT") {
          return this.sellAmount;
        } else {
          return this.sellAmount * this.valuePerUnit;
        }
      }

    },
    valuePerUnit() {
      return this.selectedMarketCoin.value;
    },
    sellBalanceInUSDT() {
      let wallets = this.$store.getters.wallet.wallet;
      return wallets.find((item) => item.name === this.selectedCoin).usdt_value;
    },
    sellBalanceInCoin() {
      let wallets = this.$store.getters.wallet.wallet;
      let balance = wallets.find(
        (item) => item.name === this.selectedCoin
      ).coin_value;

      return balance;
    },
    buyBalanceInUSDT() {
      let wallets = this.$store.getters.wallet.wallet;
      return wallets.find((item) => item.name === "USDT").usdt_value;
    },
    buyBalanceInCoin() {
      let wallets = this.$store.getters.wallet.wallet;
      let balance =
        wallets.find((item) => item.name === "USDT").usdt_value /
        this.valuePerUnit;
      return balance;
    },
    balanceToDisplay() {
      return this.priceType == "TOTAL"
        ? this.buyBalanceInCoin
        : this.buyBalanceInUSDT
    },
    sellBalanceToDisplay() {
      return this.priceType == "TOTAL"
        ? this.sellBalanceInCoin
        : this.sellBalanceInUSDT;

      // return 0;
    },
    minimumSell() {
      if (this.minimum.minimum_sell == "0") return 0;
      else {
        if (this.priceType == "TOTAL") return this.minimum.minimum_sell;
        else return this.minimum.minimum_sell * this.valuePerUnit;
      }
    },
    minimumBuy() {
      if (this.minimum.account_balance_limit_spot == "0") return 0;
      else {
        if (this.priceType == "TOTAL")
          return this.minimum.account_balance_limit_spot;
        else return this.minimum.account_balance_limit_spot * 1;
      }
    },
    orders: function () {
      return this.$store.state.orders;
    },
  },
  methods: {
    rowClicked(value) {
      const name = (value.coin_pair_name).replace('USDT', '')
      console.log(name)
      this.$store.state.selectedCoin = name;
      this.$router.push(`/trades/spot/${name}`);
      this.coinsDialog = false
    },

    openChart() {
      this.$router.push('/trades/trade-chart/' + this.selectedCoin)
    },
    openDialog() {
      // console.log(this.dialog); // Log the current value of dialog
      this.coinsDialog = true;
    },

    closeDepositDialog() {
      this.coinsDialog = false;
    },

    pow() {
      axios.get('https://api.gmswap.net/api/v1/all-pairs', {
        params: {
          client_id: this.client.id
        }

      })
        .then(response => {
          this.powPairs = response.data.coinpair
        })
    },
    selectCoin(coin) {
      this.$router.push(`/trades/spot/${coin.name}`);
      this.navSelectedCoin = this.marketCoins.indexOf(coin) + 1;
      this.selectedCoin = coin.name;
      this.fetchMinimum(coin.name);
      location.reload()
      // // this.$route.params.coin = coin.name;
      // this.marketCoinsDialog = false;
    },
    fetchMinimum(coin) {
      this.$axios
        .get(`https://api.gmswap.net/api/v1/getOrder?symbol=${coin}`)
        .then((response) => {
          this.minimum = response.data.price;
        });
    },
    sellAmountRules() {
      return [
        (v) => !!v || "Amount is required",
        (v) =>
          v >= this.minimumSell ||
          `Amount must be greater than minimum sell amount (${this.minimumSell})`,
        (v) =>
          v <= this.sellBalanceToDisplay ||
          `Insufficient balance (${this.sellBalanceToDisplay})`,
      ];
    },
    amountRules() {
      return [
        (v) => !!v || "Amount is required",
        (v) =>
          v >= this.minimumBuy ||
          `Amount must be greater than minimum buy amount (${this.minimumBuy})`,
        (v) =>
          v <= this.balanceToDisplay ||
          `Insufficient balance (${this.balanceToDisplay})`,
      ];
    },
    passwordRules() {
      return [(v) => !!v || "Password is required"];
    },
    confirm() {
      this.delegateType = 'BUY'
      if (this.$refs.tradeForm.validate()) this.confirmDialog = true;
    },

    confirmSell() {
      this.delegateType = 'SELL'
      if (this.$refs.tradeForm.validate()) this.confirmDialog = true;
    },
    trade() {
      if (this.$refs.confirmationForm.validate()) {
        if (this.delegateType == 'BUY') {
          this.loading = true;
          this.$axios
            .get("https://api.gmswap.net/api/v2/my-order", {
              params: {
                name: this.selectedCoin,
                delegate_type: this.delegateType,
                number_of_order: this.numberOfOrder,
                total: this.total,
                order_per_unit: this.valuePerUnit,
                trade_type: "market",
                market_type: this.marketType,
                transaction_password: this.password,
              },
            })
            .then((response) => {
              this.loading = false;
              if (response.data.message == "success") {
                this.$swal({
                  title: "Success",
                  text: "Order created successfully",
                  icon: "success",
                  timer: 3000,
                  buttons: false,
                });
                this.$store.dispatch("setWallet");
                this.confirmDialog = false;
              } else if (response.data.message === "wrong_transaction_password") {
                this.$swal({
                  title: "Error",
                  text: "Wrong transaction password",
                  icon: "error",
                  timer: 3000,
                  buttons: false,
                });
                this.errorMessage.password = "Wrong transaction password";
              } else {
                this.$swal({
                  title: "Error",
                  text: "Something went wrong",
                  icon: "error",
                  timer: 3000,
                  buttons: false,
                });
              }
            })
            .catch((error) => {
              this.loading = false;
              this.$swal({
                title: "Error",
                text: error.response.data.message,
                icon: "error",
                timer: 3000,
                buttons: false,
              });
            });
        }
        else if (this.delegateType == 'SELL') {
          this.loading = true;
          this.$axios
            .get("https://api.gmswap.net/api/v2/my-order", {
              params: {
                name: this.selectedCoin,
                delegate_type: this.delegateType,
                number_of_order: this.numberOfOrder,
                total: this.total,
                order_per_unit: this.valuePerUnit,
                trade_type: "market",
                market_type: this.sellMarketType,
                transaction_password: this.password,
              },
            })
            .then((response) => {
              this.loading = false;
              if (response.data.message == "success") {
                this.$swal({
                  title: "Success",
                  text: "Order created successfully",
                  icon: "success",
                  timer: 3000,
                  buttons: false,
                });
                this.$store.dispatch("setWallet");
                this.confirmDialog = false;
              } else if (response.data.message === "wrong_transaction_password") {
                this.$swal({
                  title: "Error",
                  text: "Wrong transaction password",
                  icon: "error",
                  timer: 3000,
                  buttons: false,
                });
                this.errorMessage.password = "Wrong transaction password";
              } else {
                this.$swal({
                  title: "Error",
                  text: "Something went wrong",
                  icon: "error",
                  timer: 3000,
                  buttons: false,
                });
              }
            })
            .catch((error) => {
              this.loading = false;
              this.$swal({
                title: "Error",
                text: error.response.data.message,
                icon: "error",
                timer: 3000,
                buttons: false,
              });
            });
        }

      }
    },
    ratingText(index) {
      return index == 0
        ? "25%"
        : index == 1
          ? "50%"
          : index == 2
            ? "75%"
            : "100%";
    },
    setAmountPercentage() {
      this.amount = this.balanceToDisplay * this.amountPercentage * 0.25;
    },

    setSellAmountPercentage() {
      this.sellAmount = this.sellBalanceToDisplay * this.sellAmountPercentage * 0.25;
    },
    async getTradesChart() {
      try {
        const response = await this.$axios.get(
          `https://api.gmswap.net/api/v2/chart/chart-series?symbol=${this.selectedCoin}&timeframe=${this.timeFrame}`
        );
        //Save marketCoins to vuex
        // this.$store.commit("setMarketCoins", response.data.coinpair);
        this.dataRaw = response.data;
        //Get current price
        this.coinDetails.amount = this.dataRaw.latest_time.close;
        this.latestDetails = this.dataRaw.latest_time;
        //Get percent change
        // this.coinDetails.percent = this.dataRaw.latest_time.percent;
      } catch (error) {
        console.log(error);
        return [];
      }
    },
    async fetchOrders() {
      try {
        this.$axios
          .get(`https://api.gmswap.net/api/v2/recent-trades`, {
            params: {
              name: this.selectedCoin + "USDT",
            },
          })
          .then((response) => {
            //Save Orders to vuex
            this.$store.commit("setOrders", response.data);
            this.loading = false;
          });
      } catch (error) {
        console.log(error);
        return [];
      }
    },
  },

  mounted() {
    this.pow()
    this.selectedCoin = this.$route.params.coin;
    if (this.$store.getters.isAuthenticated) {
      this.fetchOrders();
      this.$store.dispatch("setWallets");
      this.fetchMinimum(this.selectedCoin);
    }
    // this.getTradesChart();
    //Add interval
    // this.interval = setInterval(() => {
    //   this.getTradesChart();
    // }, 5000);
  },
  beforeDestroy() { },
  beforeRouteEnter(to, from, next) {
    const isAuthenticated = store.getters.isAuthenticated;
    if (isAuthenticated) {
      next();
    } else {
      next("/signin");
    }
  },
};
</script>
<style scoped>
.right-dialog {
  left: 0;
  top: 0;
  position: fixed;
  margin: 0;
  transform: translateY(0);
  transform: translateX(0);
  width: 500px
}

.toolbar-buttons {
  background-color: transparent !important;
}

.trade-container {
  width: 100vw !important;
  border-radius: 20px !important;
  background-color: #1e1e1e !important;
}

.full-width-toggle {
  display: flex;
  width: 100%;
}

.full-width-toggle .v-btn {
  flex-grow: 1;
  flex-basis: 0;
}

.v-text-field.v-text-field--solo .v-input__control {
  height: 28px !important;
}

.v-text-field.v-text-field--solo .v-input__control .v-input__slot {
  height: 28px !important;
}

.price-list {
  font-size: 11px;
  color: #b3b3b3;
}

.list-title {
  font-size: 13px;
}

.sub-caption {
  font-size: 10px;
  color: #b3b3b3;
}

.rating-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.icon-with-label {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.rating-label {
  margin-top: 0px;
  font-size: 9px;
}

/* Button Spinner */
.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}

.volume-caption {
  font-size: 10px !important;
  margin-bottom: 0px !important;
  color: #b3b3b3;
}

@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@-o-keyframes loader {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes loader {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}
</style>
