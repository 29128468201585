<template>
    <v-row>
        <v-col cols="12" xs="12" lg="4">
            <div>
                <v-slide-group mandatory class="mt-3" v-model="selectedOption" active-class="#5E6673">
                    <v-slide-item v-for="(option, index) in options" :key="index" v-slot="{ active, toggle }">
                        <v-btn x-small :color="active ? '#FAFAFA' : '#b7bdc6 '" text class="mx-1" :input-value="active"
                            @click="toggle">
                            {{ option }}
                        </v-btn>
                    </v-slide-item>
                </v-slide-group>

                <v-alert v-if="selectedOption === 0" color="#1C1D28 " dense type="info" class="mt-4 alert"
                    style="border-radius: 10px;">
                    <span class="paragraph">High Yield products involve higher risks.</span>
                </v-alert>

                <div class="mt-3">
                    <span class="span-title">Dual Investment</span>
                    <p class="mt-3 paragraph">Monetize your market view and get access to potentially high rewards</p>
                </div>
            </div>

            <v-divider color="#b7bdc6"></v-divider>

            <v-sheet color="#00000000" class="mt-3 pa-4" style="border: 0.1px solid #b7bdc6; border-radius: 10px;">
                <div class="d-flex align-center justify-space-between">
                    <div>
                        <v-avatar size="25">
                            <img src="https://api.gmswap.net/storage/images/coins/btc-1680611649/btc-1680611649.png"
                                alt="">
                        </v-avatar>

                        <span class="ml-2">BTC</span>
                    </div>
                    <div>
                        <span class="value-percent ">3.66% - 122.87%</span>
                    </div>
                </div>
            </v-sheet>

            <v-sheet color="#00000000" class="mt-3 pa-4" style="border: 0.1px solid #b7bdc6; border-radius: 10px;">
                <div class="d-flex align-center justify-space-between">
                    <div>
                        <v-avatar size="25">
                            <img src="https://api.gmswap.net/storage/images/coins/eth-1680679720/eth-1680679720.png"
                                alt="">
                        </v-avatar>

                        <span class="ml-2">ETH</span>
                    </div>
                    <div>
                        <span class="value-percent ">3.96% - 162.98%</span>
                    </div>
                </div>
            </v-sheet>

            <div class="mt-5">
                <span class="span-subtitle">Not what you're looking for?</span>
            </div>

            <v-divider color="#b7bdc6"></v-divider>

            <div class="mt-3">
                <div class="d-flex justify-space-between">
                    <div class="">
                        <span class="span-regular">Simple Earn</span>
                        <p class="paragraph">Monetize your market view and <br> get access to potentially high
                            rewards
                        </p>
                    </div>

                    <div>
                        <v-btn @click="$router.push('/wallet/simple_earn')" icon>
                            <v-icon>mdi mdi-chevron-right</v-icon>
                        </v-btn>
                    </div>
                </div>
            </div>

        </v-col>
    </v-row>
</template>

<script>
export default {
    data() {
        return {
            selectedOption: 0,
            options: ['All', 'Dual Investment']
        }
    }
}
</script>

<style>
.span-title {
    font-size: 20px;
    font-weight: 500;
}

.span-regular {
    font-size: 1rem;
    font-weight: 500;
}

.span-subtitle {
    font-size: 12px
}

.paragraph {
    font-size: 12px;
}
</style>