<template>
    <v-row justify="center" align="center">
        <v-col xs="12" md="10">
            <div :style="!$vuetify.breakpoint.xs && 'padding: 120px 8px;'">
                <h1 style="color: #fff;" :style="{
                    fontSize: !$vuetify.breakpoint.xs ? '55px' : ($vuetify.breakpoint.sm || $vuetify.breakpoint.xs) ? '32px' : '',
                    margin: $vuetify.breakpoint.sm || $vuetify.breakpoint.xs ? 'auto' : '',
                    maxWidth: $vuetify.breakpoint.sm || $vuetify.breakpoint.xs ? '500px' : '',
                    textAlign: 'center'
                }">

                    {{ $t('landing.questions') }}
                </h1>

                <v-expansion-panels style="color: #fff;" accordion activeAccordion>
                    <v-expansion-panel style="background-color: #00000000;">
                        <v-expansion-panel-header>
                            <h4 style="color: #fff;">{{ $t('landing.question1') }}</h4>

                            <template v-slot:actions>
                                <v-icon :class="{ 'rotate-icon': activeAccordion === 0 }" color="#fff">
                                    <!-- Applying rotate-icon class conditionally -->
                                    mdi-chevron-down
                                </v-icon>
                            </template>

                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <p style="color: #fff; font-size: 18px;">
                                {{ $t('landing.answer1') }}
                            </p>
                        </v-expansion-panel-content>
                    </v-expansion-panel>

                    <!-- <v-expansion-panel style="background-color: #00000000;">
                <v-expansion-panel-header>
                <h6 style="color: #fff;">How to buy Bitcoin and other cryptocurrencies on GMSWAP</h6>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                <p style="color: #fff">There are several ways to buy cryptocurrencies on GMSWAP. You can use a credit/debit card, cash
                    balance, or Apple Pay/Google Pay to purchase crypto on GMSWAP. Before getting started, please make
                    sure
                    you’ve completed Identity Verification for your GMSWAP account.</p>
                </v-expansion-panel-content>
            </v-expansion-panel> -->

                    <v-expansion-panel style="background-color: #00000000;">
                        <v-expansion-panel-header>

                            <h4 style="color: #fff;">{{ $t('landing.question2') }}</h4>
                            <template v-slot:actions>
                                <v-icon :class="{ 'rotate-icon': activeAccordion === 1 }" color="#fff">
                                    <!-- Applying rotate-icon class conditionally -->
                                    mdi-chevron-down
                                </v-icon>
                            </template>

                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <p style="color: #fff; font-size: 18px;">
                                {{ $t('landing.answer2') }}
                            </p>
                        </v-expansion-panel-content>
                    </v-expansion-panel>

                    <v-expansion-panel style="background-color: #00000000;">
                        <v-expansion-panel-header>
                            <h4 style="color: #fff;">{{ $t('landing.question3') }}</h4>
                            <template v-slot:actions>
                                <v-icon :class="{ 'rotate-icon': activeAccordion === 2 }" color="#fff">
                                    <!-- Applying rotate-icon class conditionally -->
                                    mdi-chevron-down
                                </v-icon>
                            </template>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <p style="color: #fff; font-size: 18px;">{{ $t('landing.answer3') }} </p>
                        </v-expansion-panel-content>
                    </v-expansion-panel>

                    <v-expansion-panel style="background-color: #00000000;">
                        <v-expansion-panel-header>
                            <h4 style="color: #fff;">{{ $t('landing.question4') }}</h4>
                            <template v-slot:actions>
                                <v-icon :class="{ 'rotate-icon': activeAccordion === 3 }" color="#fff">
                                    <!-- Applying rotate-icon class conditionally -->
                                    mdi-chevron-down
                                </v-icon>
                            </template>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <p style="color: #fff; font-size: 18px;">
                                {{ $t('landing.answer4') }}
                            </p>
                        </v-expansion-panel-content>
                    </v-expansion-panel>

                </v-expansion-panels>
            </div>
        </v-col>
    </v-row>
</template>


<script>
export default {
    data: () => ({
        activeAccordion: 0,
    }),

}
</script>