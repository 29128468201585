<template>
    <v-row class="mt-2">
        <v-col cols="12">

            <v-sheet :color="!$vuetify.breakpoint.xs ? '#272727' : '#00000000'"
                :class="!$vuetify.breakpoint.xs ? 'mx-auto pa-8 mt-1' : ''" :style="sheetStyle">
                <v-row>
                    <!-- Loading indicator -->
                    <v-col v-if="isLoading" class="text-center">
                        <v-progress-circular indeterminate color="primary"></v-progress-circular>
                    </v-col>

                    <v-col cols="12">
                        <div class="d-flex justify-space-between">

                            <div class="d-flex flex-column">

                                <div class="d-flex align-center">
                                    <span style="font-size: 14px;">{{ $t('wallet.estTotal') }}</span>
                                    <v-btn icon x-small class="ml-2">
                                        <v-icon>mdi-eye</v-icon>
                                    </v-btn>
                                </div>

                                <div class="mt-2 d-flex align-center">
                                    <h2 style="font-size: 34px; font-weight: 500;">
                                        0.00
                                    </h2>
                                    <small class="ml-2">USDT</small>
                                </div>

                                <!-- <div v-if="!$vuetify.breakpoint.xs" class="d-flex">
                                    <div class="d-flex flex-column mr-10">
                                        <span style="font-size: 12px; color: #b7bdc6;">Last Day PNL(USD)</span>
                                        <span style="font-size: 14px;">$0.0</span>
                                    </div>

                                    <div class="d-flex flex-column">
                                        <span style="font-size: 12px; color: #b7bdc6;">30-Day Profit(USD)</span>
                                        <span style="font-size: 14px;">$0.0</span>
                                    </div>
                                </div> -->
                            </div>

                            <div v-if="!$vuetify.breakpoint.xs">
                                <v-btn @click="$router.push('/wallet/simple_earn')" :ripple="false" class="mr-3"
                                    style="width: 105px; text-transform: capitalize; font-size: 14px;" color="#5E6673">
                                    {{ $t('wallet.earn') }}
                                </v-btn>

                                <v-btn @click="$router.push('/wallet/auto_invest')" :ripple="false" class="mr-3"
                                    style="width: 105px; text-transform: capitalize; font-size: 14px;" color="#5E6673">
                                    {{ $t('wallet.auto') }}
                                </v-btn>

                                <!-- <v-btn :ripple="false" class="mr-3"
                                    style="width: 105px; text-transform: capitalize; font-size: 14px;" color="#5E6673">
                                    Loan
                                </v-btn> -->

                                <!-- <v-btn @click="$router.push('/wallet/transactions')" :ripple="false"
                                    style="width: 105px; text-transform: capitalize; font-size: 14px;" color="#5E6673">
                                    Transactions
                                </v-btn> -->
                            </div>

                        </div>
                    </v-col>

                    <v-col v-if="$vuetify.breakpoint.xs" cols="12">
                        <div class="mb-3">
                            <!-- <v-row>
                                <v-col cols="6">
                                    <div class="d-flex flex-column">
                                        <span style="font-size: 12px; color: #b7bdc6;">Last Day PNL(USD)</span>
                                        <span style="font-size: 14px;">$0.0</span>
                                    </div>
                                </v-col>

                                <v-col cols="6">
                                    <div class="d-flex flex-column">
                                        <span style="font-size: 12px; color: #b7bdc6;">30-Day Profit(USD)</span>
                                        <span style="font-size: 14px;">$0.0</span>
                                    </div>
                                </v-col>
                            </v-row> -->

                            <div class="mt-5 d-flex justify-space-between">
                                <div class="d-flex flex-column align-center">
                                    <v-btn @click="$router.push('/wallet/simple_earn')" class="pa-6" small :ripple="false"
                                        style="border-radius: 15px; border: 0.1px solid #b7bdc6;" color="#00000000"
                                        elevation="2" fab tile>
                                        <v-icon size="20">mdi mdi-piggy-bank-outline</v-icon>
                                    </v-btn>
                                    <span class="mt-2" style="font-size: 12px;">
                                        {{ $t('wallet.earn') }}
                                    </span>
                                </div>


                                <div class="d-flex flex-column align-center">
                                    <v-btn @click="$router.push('/wallet/auto_invest')" class="pa-6" small :ripple="false"
                                        style="border-radius: 15px; border: 0.1px solid #b7bdc6;" color="#00000000"
                                        elevation="2" fab tile>
                                        <v-icon size="20">mdi mdi-sync</v-icon>
                                    </v-btn>
                                    <span class="mt-2" style="font-size: 12px;">
                                        {{ $t('wallet.auto') }}
                                    </span>

                                </div>

                                <div class="d-flex flex-column align-center">
                                    <v-btn @click="$router.push('/page-unavailable')" class="pa-6" small :ripple="false"
                                        style="border-radius: 15px; border: 0.1px solid #b7bdc6;" color="#00000000"
                                        elevation="2" fab tile>
                                        <v-icon size="20">mdi mdi-cash</v-icon>
                                    </v-btn>
                                    <span class="mt-2" style="font-size: 12px;">{{ $t('wallet.loan') }}</span>
                                </div>

                                <!-- <div class="d-flex flex-column align-center">
                                    <v-btn @click="$router.push('/wallet/transactions')" class=" pa-6" small :ripple="false"
                                        style="border-radius: 15px; border: 0.1px solid #b7bdc6;" color="#00000000"
                                        elevation="2" fab tile>
                                        <v-icon size="20">"mdi mdi-format-list-bulleted-square</v-icon>
                                    </v-btn>
                                    <span class="mt-2" style="font-size: 12px;">Transactions</span>
                                </div> -->

                            </div>

                        </div>

                    </v-col>

                    <v-divider v-if="$vuetify.breakpoint.xs" color="#b7bdc6"></v-divider>

                    <v-col v-if="$vuetify.breakpoint.xs" cols="12">
                        <div>
                            <v-slide-group mandatory v-model="selectedOption" active-class="#5E6673">
                                <v-slide-item v-for="(option, index) in options" :key="index" v-slot="{ active, toggle }">
                                    <v-btn :color="active ? '#FAFAFA' : '#b7bdc6 '" text class="mx-1" :input-value="active"
                                        @click="toggle">
                                        {{ option }}
                                    </v-btn>
                                </v-slide-item>
                            </v-slide-group>
                        </div>

                        <div v-if="selectedOption === 0">
                            <AssetTab />
                        </div>

                        <div v-if="selectedOption === 1">
                            <ProductTab />
                        </div>
                    </v-col>

                </v-row>
            </v-sheet>
        </v-col>

        <!-- desktop tabs -->
        <v-col v-if="!$vuetify.breakpoint.xs" cols="12">
            <v-tabs v-model="tab" background-color="#00000000" bg-color="transparent" class="tabs-right">

                <v-tab value="one"> {{ $t('wallet.asset2') }}</v-tab>
                <v-tab-item class="pa-1">
                    <AssetTab />
                </v-tab-item>

                <v-tab value="two"> {{ $t('wallet.product') }}</v-tab>
                <v-tab-item class="pa-1">
                    <ProductTab />
                </v-tab-item>

            </v-tabs>
        </v-col>


    </v-row>
</template>

<script>
import AssetTab from './AssetTab.vue';
import ProductTab from './ProductTab.vue'

export default {

    components: {
        AssetTab,
        ProductTab,
    },

    props: {
        sheetStyle: [Function, Object],
        isLoading: Boolean,
    },

    data() {
        return {
            options: ['Asset', 'Product'],
            selectedOption: 0,
            headerFutures: [
                {
                    value: "name",
                    text: "Name",
                    align: "start",
                    sortable: true,
                },
                {
                    value: "balance",
                    text: "Wallet Balance",
                    align: "end",
                    sortable: true,
                },

                // {
                //     value: "actions",
                //     text: "Actions",
                //     sortable: true,
                //     align: "end",
                // },
            ],

            earnStaking: [],
        }
    },

    mounted() {
        this.fetchAssets()
    },

    methods: {
        fetchAssets() {
            this.$axios.get('https://api.gmswap.net/api/v1/stake')
                .then(response => {
                    this.earnStaking = response.data.earnStaking
                })
        }
    },
}
</script>
