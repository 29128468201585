<template>
   <v-container>
       <v-row class="mt-10">
         <v-col cols="12" align="end">
            <h4 class="mt-5 mb-5" style="color:#FFD600">Miners</h4>
            <v-divider></v-divider>
         </v-col>     
         
         <v-col class="mt-5" cols="12">          
            <div class="d-flex align-center justify-space-between" >
               <h2>Mining in Progress...</h2>

               <div class="d-flex align-center">
                  LIVE
               <span style="color:#CD4B39; font-size: 2rem;" class="ml-2 mdi mdi-circle-slice-8"></span>
               </div>
            </div>
            
         </v-col>

         <v-col cols="12">

            <v-card color="#00000000" class=" pa-5" style="border-radius: 20px; border: 1px solid #9493AC">
               <v-data-table
               :headers="headers"
               :items="spotItems"
               :items-per-page="10"
               @click:row="rowClicked($event)"
               class="transparent"
            >

            <template v-slot:item.name="{ item }">
               <v-row>
               <v-col cols="2" class="text-center" style="margin-top:2px">
                  <v-avatar  size="30">
                     <v-img :src="item.image"></v-img>
                  </v-avatar>
               </v-col>
               <v-col cols="6">
                  <span style="margin-top:50px">{{ item.name}}</span><br/>
                  <span style="font-size:10px;color:grey">{{ (item.name).replace('USDT', '')}}</span>
                  <!-- <span> {{ item.date }}</span><br/> -->
               </v-col>
               </v-row>
            </template>

            <template v-slot:item.value="{ item }">
               <v-row>
                  <v-col cols="6">
                  <span >{{ parseFloat(item.coin_value).toFixed(4) }}</span><br/>
                  <span  style="font-size:10px;color:grey">${{ parseFloat(item.usdt_value).toFixed(2) }}</span>
                  <!-- <span style="font-size:10px;color:grey">{{ (item.coin_pair_name).replace('USDT', '')}}</span> -->
                  <!-- <span> {{ item.date }}</span><br/> -->
                  </v-col>
               </v-row>
            </template>

            <template v-slot:item.actions="{ item }">
               <v-btn text class="transparent mr-1">BUY <v-icon>mdi-chevron-right</v-icon></v-btn>
               <v-btn text class="transparent mr-1">SELL <v-icon>mdi-chevron-right</v-icon></v-btn>
               <v-btn text class="transparent mr-1">TRADE <v-icon>mdi-chevron-right</v-icon></v-btn>
            </template>

               </v-data-table>
            </v-card>
            
         </v-col>

       </v-row>
   </v-container>
</template>

<script>
export default {
 
   data() {
   return {
     show: false,
   };
 },

 methods: {
   toggleShow() {
     this.show = !this.show;
   },
 },
};

</script>