<template>
    <v-row class="mt-2">
        <v-col cols="12">
            <v-sheet :color="!$vuetify.breakpoint.xs ? '#272727' : '#00000000'"
                :class="!$vuetify.breakpoint.xs ? 'mx-auto pa-8 mt-1' : ''" :style="sheetStyle">
                <v-row>
                    <!-- Loading indicator -->
                    <v-col v-if="isLoading" class="text-center">
                        <v-progress-circular indeterminate color="primary"></v-progress-circular>
                    </v-col>

                    <v-col v-else cols="12">
                        <div class="d-flex justify-space-between">
                            <div class="d-flex flex-column">

                                <div class="d-flex align-center">
                                    <span style="font-size: 14px;"> {{ $t('wallet.margin') }}</span>
                                    <v-btn icon x-small class="ml-2">
                                        <v-icon>mdi-eye</v-icon>
                                    </v-btn>
                                </div>

                                <div class="mt-2 d-flex align-center">
                                    <h2 style="font-size: 34px; font-weight: 500;">
                                        {{ parseFloat(overviewTotal.futureTotal).toFixed(2) }}
                                    </h2>
                                    <small class="ml-2">USDT</small>
                                </div>

                                <div class="d-flex align-center">
                                    <!-- <span style="font-size: 14px;">Todays Realized PnL</span>

                                    <v-skeleton-loader class="ml-2" v-if="futurePnl === undefined || futurePnl === null"
                                        :loading="!futurePnl" type="text" :width="'40px'"
                                        :height="'20px'"></v-skeleton-loader>

                                    <v-skeleton-loader class="ml-2"
                                        v-if="futurePnlPercentage === undefined || futurePnlPercentage === null"
                                        :loading="!futurePnlPercentage" type="text" :width="'40px'"
                                        :height="'20px'"></v-skeleton-loader>


                                    <div class="ml-2" v-else-if="futurePnl < 0">
                                        <span style="color:#CA3F64 ">{{ parseFloat(futurePnl).toFixed(2) }}</span>
                                        <span style="color:#CA3F64 ">({{ parseFloat(futurePnlPercentage).toFixed(2) }}%)
                                        </span>
                                    </div>
                                    <div class="ml-2" v-else-if="futurePnl > 0">
                                        <span style="color:#0ECB81 ">{{ parseFloat(futurePnl).toFixed(2) }}</span>
                                        <span style="color:#0ECB81 ">({{ parseFloat(futurePnlPercentage).toFixed(2) }}%)
                                        </span>
                                    </div>
                                    <div class="ml-2" v-else>
                                        <span style="color:#0ECB81 ">{{ parseFloat(futurePnl).toFixed(2) }}</span>
                                        <span style="color:#0ECB81 ">({{ parseFloat(futurePnlPercentage).toFixed(2) }}%)
                                        </span>
                                    </div> -->

                                    <!-- <div class="ml-2">
                                        <span v-if="futurePnlPercentage > 0" style="color:#1C1D28; font-size: 14px;">+
                                            {{ parseFloat(futurePnlPercentage).toFixed(2) }}</span>
                                        <span v-else-if="futurePnlPercentage < 0" style="color:red; font-size: 14px;">
                                            {{ parseFloat(futurePnlPercentage).toFixed(2) }}</span>
                                        <span v-else-if="futurePnlPercentage == 0" style="color:grey; font-size: 14px;">
                                            {{ parseFloat(futurePnlPercentage).toFixed(2) }}</span>
                                    </div> -->
                                </div>
                            </div>

                            <div v-if="!$vuetify.breakpoint.xs">
                                <!-- <v-btn :ripple="false" small class="mr-3"
                                    style="width: 116px; text-transform: capitalize; font-size: 14px;" color="#5E6673">
                                    Deposit
                                </v-btn> -->


                                <!-- <v-btn @click="handleNavigateConvert()" :ripple="false" class="mr-3"
                                    style="width: 116px; text-transform: capitalize; font-size: 14px;" color="#5E6673">
                                    Convert
                                </v-btn> -->

                                <v-btn @click="handleNavigateTransfer()" :ripple="false"
                                    style="width: 116px; text-transform: capitalize; font-size: 14px;" color="#5E6673">
                                    {{ $t('wallet.transfer') }}
                                </v-btn>
                            </div>

                        </div>
                    </v-col>

                    <v-col v-if="$vuetify.breakpoint.xs" cols="12" align="start">
                        <div class="mb-3">
                            <v-row>
                                <v-col cols="6">
                                    <div class="d-flex flex-column">
                                        <span style="font-size: 12px; color: #b7bdc6;">{{ $t('wallet.walletBalance') }}
                                            (USD)</span>
                                        <span style="font-size: 14px;">$0.000000</span>
                                    </div>
                                </v-col>

                                <v-col cols="6">
                                    <div class="d-flex flex-column">
                                        <!-- <span style="font-size: 12px; color: #b7bdc6;">Unrelized PNL (USD)</span>
                                        <span style="font-size: 14px;">$0.000000</span> -->
                                    </div>
                                </v-col>
                            </v-row>

                            <v-row dense class="mt-5">
                                <!-- <v-col cols="4">
                                    <ButtonComponent label="Trade" :block="true" color="primary"
                                        style="font-size: 14px;color: #1C1D28; border-radius: 8px;" />
                                </v-col> -->

                                <v-col cols="6">
                                    <ButtonComponent :onClick="handleNavigateConvert" label="Convert" :block="true"
                                        color="#5E6673" style="font-size: 14px; border-radius: 8px;" />
                                </v-col>

                                <v-col cols="6">
                                    <ButtonComponent :onClick="handleNavigateTransfer" label="Transfer" :block="true"
                                        color="#5E6673" style="font-size: 14px; border-radius: 8px;" />
                                </v-col>
                            </v-row>
                        </div>
                    </v-col>

                    <v-divider v-if="$vuetify.breakpoint.xs" color="#b7bdc6"></v-divider>

                    <v-col v-if="$vuetify.breakpoint.xs" cols="12">
                        <div>
                            <v-slide-group mandatory v-model="selectedOption" active-class="#5E6673">
                                <v-slide-item v-for="(option, index) in options" :key="index" v-slot="{ active, toggle }">
                                    <v-btn :color="active ? '#FAFAFA' : '#b7bdc6 '" text class="mx-1" :input-value="active"
                                        @click="toggle">
                                        {{ option }}
                                    </v-btn>
                                </v-slide-item>
                            </v-slide-group>
                        </div>

                        <div v-if="selectedOption === 0">
                            <PositionsTab />
                        </div>

                        <div v-if="selectedOption === 1">
                            <AssetsTab />
                        </div>
                    </v-col>

                </v-row>
            </v-sheet>
        </v-col>

        <v-col v-if="!$vuetify.breakpoint.xs" cols="12">
            <v-tabs v-model="tab" background-color="#00000000" bg-color="transparent" class="tabs-right">

                <v-tab value="one"> {{ $t('wallet.positions') }}</v-tab>
                <v-tab-item class="pa-1">
                    <PositionsTab />
                </v-tab-item>

                <v-tab value="two"> {{ $t('wallet.assets') }}</v-tab>
                <v-tab-item class="pa-1">
                    <AssetsTab />
                </v-tab-item>

            </v-tabs>
        </v-col>

    </v-row>
</template>

<script>
import axios from 'axios'
import SearchBarComponent from '@/components/ui/SearchBarComponent.vue';
import ButtonComponent from '@/components/ui/ButtonComponent.vue';

import PositionsTab from './PositionsTab.vue';
import AssetsTab from './AssetsTab.vue';
export default {

    components: {
        SearchBarComponent,
        ButtonComponent,
        PositionsTab,
        AssetsTab,
    },

    data() {
        return {
            wallets: [],
            options: ['Positions', 'Assets'],
            selectedOption: 0,
            margins: [],
            headerFutures: [
                {
                    value: "name",
                    text: "Name",
                    align: "start",
                    sortable: true,
                },
                {
                    value: "balance",
                    text: "Wallet Balance",
                    align: "end",
                    sortable: true,
                },

                // {
                //     value: "actions",
                //     text: "Actions",
                //     sortable: true,
                //     align: "end",
                // },
            ],
        }
    },

    methods: {
        rowClicked(value) {
            const name = (value.coin.name);
            this.$store.state.selectedCoin = name;
            this.$router.push(`/trades/trade-chart/${name}`);
        },

        mobileRowClicked(value) {
            const name = (value.name)
            // this.$store.state.selectedCoin = name;
            // this.$router.push(`/wallet/${name}`);
        },

        handleNavigateConvert() {
            this.$router.push('/wallet/convert')
        },

        handleNavigateTransfer() {
            this.$router.push('/wallet/transfer')
        },

        fetchMargins() {
            this.loading = true;
            this.$axios
                .get("https://api.gmswap.net/api/v2/client-margin")
                .then((response) => {
                    this.margins = response.data.margin;
                    this.loading = false;
                });
        },



    },

    mounted() {
        this.fetchMargins()
    },

    props: {
        futureItems: Array,
        overviewTotal: Object | Array,
        goToWithdraw: Function,
        goToDeposit: Function,
        goToHistory: Function,
        futurePnlPercentage: [Number, String],
        futurePnl: [Number, String],
        sheetStyle: [Function, Object],
        isLoading: Boolean,
    },
}
</script>

<style scoped>
.btnPrimary {
    font-size: 14px;
    color: #1C1D28;
    border-radius: 8px;
}

.v-tabs-bar.v-item-group {
    border-bottom: none !important;
}
</style>