<template>
    <v-row>
        <h3 style="color:#FFD600; font-size: 48px;">
            Quick Actions
        </h3>

        <v-col cols="12" lg="3">
            <v-sheet 
                @click="goToDeposit()"
                class="pa-10"
                color="#00000000" 
                style="cursor:pointer; border: 1px solid #9493AC; border-radius: 20px;"
                >
                
                <div class="d-flex align-center flex-column">
                    <div class="mt-5">
                        <img src="@/assets/umichain/Deposit.png" />
                    </div>
                    <span style="color: #FAFBFC; font-size: 32px;">Deposit</span>  
                </div>     
            </v-sheet>
        </v-col>

        <v-col cols="12" lg="3">
            <v-sheet 
                    @click="goWithdraw()"
                    class="pa-10"
                    color="#00000000" 
                    style="border: 1px solid #9493AC; border-radius: 20px; "
                >
                
                <div class="d-flex align-center flex-column">
                    <div class="mt-5">
                        <img src="@/assets/umichain/withdraw.png" />
                    </div>
                    <span style="color: #FAFBFC; font-size: 32px;">Withdraw</span>  
                </div>     
            </v-sheet>
        </v-col>

        <v-col cols="12" lg="3">
            <v-sheet 
                class="pa-10"
                color="#00000000" 
                style="border: 1px solid #9493AC; border-radius: 20px;"
            >
                <div class="d-flex align-center flex-column">
                    <div class="mt-5">
                        <img src="@/assets/umichain/help.png" />
                    </div>
                    <span style="color: #FAFBFC; font-size: 32px;">Help</span>  
                </div>     
            </v-sheet>
        </v-col>

        <v-col cols="12" lg="3">
            <v-sheet 
                class="pa-10"
                color="#00000000" 
                style="border: 1px solid #9493AC; border-radius: 20px;"
                >
                
                <div class="d-flex align-center flex-column">
                    <div class="mt-5">
                        <img src="@/assets/umichain/support.png" />
                    </div>
                    <span style="color: #FAFBFC; font-size: 32px;">Deposit</span>  
                </div>     
            </v-sheet>
        </v-col>

    </v-row>
</template>

<script>
export default {
    methods: {
        handleSheetClick() {
            // console.log('Sheet clicked');
        },

        goToDeposit() {
            this.$router.push({path: `/wallet/deposit`});
        }
    }
}

</script>