<template>
  <div>
    <v-container v-if="!loading" class="mt-4 market-containers">
      <div>
        <main-earn-coin v-for="coin in filteredCoins" :key="coin.id" :name="coin.name" :image="coin.image" :apr="coin.apr"
          :earn="coin.earn" />
      </div>
    </v-container>
    <v-container v-else class="mt-4 market-containers">
      <div class="text-center">
        <v-progress-circular indeterminate :size="100" :width="7" color="secondary" class="mt-5"></v-progress-circular>
      </div>
    </v-container>
  </div>
</template>
<script>
export default {
  props: {
    search: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    loading: false,
    coins: [],
  }),
  computed: {
    filteredCoins() {
      // Filter coins based on search value
      return this.coins.filter((coin) => {
        return coin.name.toLowerCase().includes(this.search.toLowerCase());
      });
    },
  },
  methods: {
    async fetchEarn() {
      this.loading = true;
      try {
        const response = await this.$axios.get(
          "https://api.gmswap.net/api/v1/earn/view"
        );
        this.loading = false;
        this.coins = response.data.earn;
      } catch (error) {
        console.log(error);
        return [];
      }
    },
  },
  mounted() {
    this.fetchEarn();
  },
};
</script>
<style scoped>
.v-tab {
  min-width: 60px !important;
}

.theme--dark.v-tabs-items {
  background-color: transparent !important;
}

.market-containers {
  border-radius: 20px !important;
  background-color: #1e1e1e !important;
}
</style>
