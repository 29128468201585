<template>
    <v-row class="transparent">
        <v-list class="transparent">
            <v-list-item
                class="transparent my-2"
                v-for="(item, index) in items"
                :key="index"
                :to="item.to"
            >
                <v-list-item-content>
                    <v-list-item-title class="font-weight-bold">
                        <v-icon left> {{ item.icon }} </v-icon>
                        {{ item.title }}
                    </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                    <v-btn icon>
                        <icon icon="fa:chevron-right" width="16" height="16" />
                    </v-btn>
                </v-list-item-action>
            </v-list-item>
            <v-list-item
                class="transparent my-2"
                href="https://direct.lc.chat/15478572/"
                target="_blank"
            >
                <v-list-item-content>
                    <v-list-item-title class="font-weight-bold">
                        <v-icon left> mdi-face-agent </v-icon>
                        Help & Support
                    </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                    <v-btn icon>
                        <icon icon="fa:chevron-right" width="16" height="16" />
                    </v-btn>
                </v-list-item-action>
            </v-list-item>
        </v-list>
    </v-row>
</template>
<script>
export default {
    props: {
        items: {
            type: Array,
            default: () => [],
        },
    },
};
</script>
