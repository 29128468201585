<template>
  <div>
    <main-toolbar />

    <v-row v-if="user === null" fluid>
      <v-col cols="12">
        <main-view />
      </v-col>
    </v-row>

    <v-row v-else>
      <v-col cols="12">
        <main-view />
      </v-col>
    </v-row>

  </div>
</template>
<script>

import { fetchPowPairs } from '@/services/marketServices';

export default {

  data: () => ({
    components: ["Home", "Trades", "Wallet", "Markets", "Futures"],
    scaffold_title: 'MARKET',
    isLoading: false,
  }),

  computed: {
    checkRoutes() {
      return this.components.includes(this.$route.name);
    },

    user() {
      return this.$store.getters.user;
    },

    powPairs() {
      return this.$store.getters.powPairs
    },

    inAuthentication() {
      return this.$route.name === "SignIn" || this.$route.name === "SignUp";
    },
  },

  mounted() {
    this.fetchPowPairsData()

    setInterval(() => {
      this.fetchPowPairsData();
    }, 50000); // 20,000 milliseconds = 20 seconds
  },

  methods: {
    async fetchPowPairsData() {
      try {
        this.isLoading = true
        let powPair = await fetchPowPairs();
        this.powPairs = powPair;
      } catch (error) {
        console.error('Failed to fetch data:', error);
      } finally {
        this.isLoading = false
      }
    },

    market() {
      this.scaffold_title = 'MARKET'
    },

    trade() {
      this.scaffold_title = 'TRADE'
    },

    futures() {
      this.scaffold_title = 'FUTURES'
    },

    wallet() {
      this.scaffold_title = 'WALLET'
    },

    profile() {
      this.scaffold_title = 'PROFILE'
    },
  }
};
</script>

<style scoped>
.custom-margin {
  margin-top: 50px;
}

.sticky-sidebar {
  position: sticky;
  top: 0;
  height: 100vh;
  overflow-y: auto;
}
</style>
